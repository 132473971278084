import React, { useEffect, useRef, useState, useReducer } from "react";
import { ObjectService, ObjectSubscriptionService } from "@getvia/sdk";


export default function DicingPressureModeDTDL({DTDLState, gasBoxState,IOWrite,executeObject,setModescreen,modescreen,}) {


  const [time, settime] = useState(null);
  const [throtilvalve, setthrotilvalve] = useState(null);
  const [CDA, setCDA] = useState(null);
  const [pressure,setPressure] = useState()
  const [position,setPosition] = useState("")
  const [actualPressure,setActualPressure] = useState()
  const [actualPosition,setActualPosition] = useState("")
  const [throttleActionMsg, setThrottleActionMsg] = useState("")

  const throttleObjectId = "dtmi:com:via:Throttle:DicingChamberThrottleValve;1"
  const throttleControlModeCmd = "SetControlMode"
  const throttlePressureCmd = "SetPressure"
  const throttlePositionCmd = "SetPosition"
  const throttleOpenMode = 4
  const throttleCloseMode = 3
  const throttlePressureMode = 5
  const throttlePositionMode = 2

  const viaConfig = {
    objectServiceOptions: {
      apiToken: "test",
      baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
      wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB,
    },
  };
  const objectService = new ObjectService(viaConfig.objectServiceOptions);
  const objectSubscriptionService = new ObjectSubscriptionService(
    viaConfig.objectServiceOptions
  );

  const modeFunction = async (e) => {
    let val = e;
    if(e==2) {
      val = 5;
    } else {
      val=2;
    }
    // IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', val);
    var dtObject = DTDLState['DicingChamberThrottleValve'];
    executeCommand(DTDLState['DicingChamberThrottleValve'].$dtId, "SetControlMode", {ControlMode: val});
    dtObject["Control_Mode"] = val;
    // setMode(val)
    console.log(val)
   }
 const setpressureValue = async () =>{
    // IOWrite('MAIN.DicingChamberThrottleValve.Out.Target_Pressure', pressure);
    executeCommand(throttleObjectId, throttlePressureCmd, {
      Pressure: pressure
    });
    setModescreen(!modescreen)
 }
 const setPositionValue = async () =>{
    // IOWrite('MAIN.DicingChamberThrottleValve.Out.Target_Position', position);
    executeCommand(throttleObjectId, throttlePositionCmd, {
      Position: position
    });
    setModescreen(!modescreen)
 }

 const executeCommand = async (objectId, commandName, params) => {
  const request = {
    instanceId: objectId,
    commandName: commandName,
    parameters: params,
  };
  const response = await objectService.ExecuteCommand(request);

  console.log(response);
  if(objectId===throttleObjectId && !response.success){
    setThrottleActionMsg(()=>response.message.replace("Error invoking method:",""))
    setTimeout(()=>setThrottleActionMsg(""),5000)
  }
};

// const executeHandler = () => {
//   if (popUp === PlaceCommand || popUp === PickCommand) {
//     executeCommand(objectId, popUp, {
//       Arm: arm,
//       Slot: slot,
//       Station: station,
//     });
//   }

//   if (popUp === GotoCommand) {
//     executeCommand(objectId, GotoCommand, { Station: station });
//   }

//   setArm(() => null);
//   setSlot(() => null);
//   setStation(() => null);
//   setPopUp(() => "hidden");
// };

const DicingThrottleValveAction=async(throttleMode)=>{

  executeCommand(throttleObjectId, throttleControlModeCmd, {
    ControlMode: throttleMode
  });

  setthrotilvalve(null)
}
const resetThrottleValve=async()=> {
  // IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 0);

  var dtObject = DTDLState['DicingChamberThrottleValve'];
  executeObject(dtObject.$dtId, "SetControlMode", {
    ControlMode: 0
  });

  setTimeout(async () => {
    // await IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 3);
    await executeObject(dtObject.$dtId, "SetControlMode", {      ControlMode: 3    });
  }, 2000);
 } 
 const OpenDicingThrottleValve=async()=>{
  // IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)
  var formdata = {
    token: "",
    ruleType: "Sequence",
    ruleGroups: [],
    ruleSubGroups: [],
    rule: "",
    sequence: 'CheckAndOpenDicingThrottleValve',
    inputData: {
    },
  };
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
      body: JSON.stringify(formdata),
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not start execution of CheckAndOpenDicingThrottleValve.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let currInstanceId = data[0].resultValue;
      console.log('CheckAndOpenDicingThrottleValve succeeded with instance id: '+currInstanceId)
      setthrotilvalve(null)
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error start execution of CheckAndOpenDicingThrottleValve :", error);
      setthrotilvalve(null)
    });

 }
 const ChangePositionModeDicingThrottleValve=async()=>{
  // IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)
  var formdata = {
    token: "",
    ruleType: "Sequence",
    ruleGroups: [],
    ruleSubGroups: [],
    rule: "",
    sequence: 'CheckAndChangePositionModeDicingThrottle',
    inputData: {
    },
  };
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
      body: JSON.stringify(formdata),
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not start execution of CheckAndChangePositionModeDicingThrottle.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let currInstanceId = data[0].resultValue;
      console.log('CheckAndChangePositionModeDicingThrottle succeeded with instance id: '+currInstanceId)
      setthrotilvalve(null)
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error start execution of CheckAndChangePositionModeDicingThrottle :", error);
      setthrotilvalve(null)
    });

 }
 const ChangePressureModeDicingThrottleValve=async()=>{
  // IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)
  var formdata = {
    token: "",
    ruleType: "Sequence",
    ruleGroups: [],
    ruleSubGroups: [],
    rule: "",
    sequence: 'CheckAndChangePressureModeDicingThrottle',
    inputData: {
    },
  };
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
      body: JSON.stringify(formdata),
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not start execution of CheckAndChangePressureModeDicingThrottle.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let currInstanceId = data[0].resultValue;
      console.log('CheckAndChangePressureModeDicingThrottle succeeded with instance id: '+currInstanceId)
      setthrotilvalve(null)
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error start execution of CheckAndChangePressureModeDicingThrottle :", error);
      setthrotilvalve(null)
    });

 }
 useEffect(() => {
  // const interval = setInterval(() => {
  //   settime(new Date())
  //   console.log(new Date())
  // }, 1000);

  // Cleanup the interval on component unmount
  // return () => clearInterval(interval);
}, []);


 useEffect(() => {
  // setPressure(gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberThrottleValve.Out.Target_Pressure").currentValue)
  // setPosition(gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberThrottleValve.Out.Target_Position").currentValue)
  // setActualPosition(gasBoxState?.find(o => o.readChannelName === "MAIN.DicingChamberThrottleValve.In.Actual_Position").currentValue)
  // setActualPressure(gasBoxState?.find(o => o.readChannelName === "MAIN.DicingChamberThrottleValve.In.Actual_Pressure").currentValue)

  setPressure(DTDLState["DicingChamberThrottleValve"].Actual_Pressure)
  setPosition(DTDLState["DicingChamberThrottleValve"].Actual_Position)
  setActualPosition(DTDLState["DicingChamberThrottleValve"].Actual_Position)
  setActualPressure(DTDLState["DicingChamberThrottleValve"].Actual_Pressure)
}, [DTDLState]);
  return (<>
    <div className="text-red text-sm">{throttleActionMsg}</div>
    <div className="text-black">
      <div className="grid grid-cols-2 gap-3 mt-2 bg-gray-200 rounded-full py-3 relative">
        <div className={`flex justify-center ${DTDLState["DicingChamberThrottleValve"].Control_Mode==4&& 'font-bold'}`}>
        {/* gasBoxState?.find(o => o.name === "DicingChamberThrottleValveControlMode").currentValue */}

          <div 
          // onClick={()=>{IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 4)}}
          onClick={()=>setthrotilvalve('Open')}
          className={`rounded-full h-5 w-5 hover:scale-125 active:scale-95 ${DTDLState["DicingChamberThrottleValve"].Control_Mode==4? 'bg-cyan-400':'bg-gray-300'}  border-2 shadow-inner shadow-gray-500  top-1 transition-all mt-1 mr-2`}></div> 
          Open
        </div>
        <div className={`flex justify-center ${DTDLState["DicingChamberThrottleValve"].Control_Mode==3&& 'font-bold'}`}>
          <div 
          // onClick={()=>{IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 3)}}
          onClick={()=>setthrotilvalve('Close')}
          className={`rounded-full h-5 w-5  hover:scale-125 active:scale-95   ${DTDLState["DicingChamberThrottleValve"].Control_Mode==3? 'bg-cyan-400':'bg-gray-300'}  border-2 shadow-inner shadow-gray-500  top-1 transition-all mt-1 mr-2`}></div> 
          Close
        </div>
      </div>
        {/* sub confirmation window start */}
        {throtilvalve!=null&&
        <div className=" absolute z-20 h-full top-0 w-full left-0">
            <div className="bg-white w-4/5 mx-auto rounded-2xl shadow-lg border-gray-400 border mt-20">
              <div className="text-center font-bold text-sm py-3">{throtilvalve} Throttle Valve?</div>
              <div className="grid grid-cols-2 gap-1 px-2 pb-2">
                {throtilvalve=='Open'?
                // <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" onClick={()=>{IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 4);setthrotilvalve(null)}}>Yes</button>
                <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300"  onClick={()=>{OpenDicingThrottleValve();}} //onClick={()=>{DicingThrottleValveAction(throttleOpenMode);}}
                >Yes</button>
                : throtilvalve=='Close'?
                <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" 
                onClick={()=>{ executeCommand(DTDLState['DicingChamberThrottleValve'].$dtId, "SetControlMode", {ControlMode: 3});
                  // IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 3);
                  setthrotilvalve(null)}}>Yes.</button>
                :throtilvalve=='Reset'?
                <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" onClick={()=>{resetThrottleValve();setthrotilvalve(null)}}>Yes</button>
               :''
                }
                <button className="shadow bg-gray-700 text-white px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-900" onClick={()=>setthrotilvalve(null)}>No</button>
              </div>
            </div>     
        </div>
        }
        {/* sub confirmation window start */}
      <div className="grid grid-cols-2 gap-3 mt-2 bg-gray-200 rounded-full p-2 relative">
        <button className={`flex justify-center bg-gray-300 px-2 py-1 rounded-3xl   hover:scale-105 transition-all`}  onClick={()=>setthrotilvalve('Reset')}>
          Reset
        </button>
        <button className={`flex justify-between bg-gray-300 px-2 pl-4 py-1  rounded-3xl  hover:scale-105 transition-all`}  onClick={()=>{DTDLState["DicingChamberThrottleValve"].OpenClose==true?setCDA('Off') :setCDA('On')}}>
          <span className="font-bold">CDA</span> 
          <div className="flex">
          {/* gasBoxState?.find(o => o.name === "DicingChamberThrottleValveCDAOnOff").currentValue */}
            {DTDLState["DicingChamberThrottleValve"].OpenClose==true ?         
            <>On <div className="h-5 w-5 rounded-full bg-flip-label-active ml-2 top-0.5 relative border border-green-600"></div></>
            :
            <>Off <div className="h-5 w-5 rounded-full  bg-gray-500 ml-2 top-0.5 relative border border-gray-600"></div></>
            }
          </div>
        </button>
      </div>
      
        {CDA!=null&&
        <div className=" absolute z-20 h-full top-0 w-full left-0">
            <div className="bg-white w-4/5 mx-auto rounded-2xl shadow-lg border-gray-400 border mt-20">
              <div className="text-center font-bold text-sm py-3">{CDA} CDA</div>
              <div className="grid grid-cols-2 gap-1 px-2 pb-2">
                {CDA=='On'?
                <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" 
                  onClick={()=>{IOWrite('MAIN.DicingChamberVat_Valve_Interlock.Out.Open_cmd', true);setCDA(null)}}>Yes</button>
                : CDA=='Off'?
                <button className="shadow bg-white  px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-300" 
                onClick={()=>{IOWrite('MAIN.DicingChamberVat_Valve_Interlock.Out.Open_cmd', false);setCDA(null)}}>Yes</button>
               :''}
                <button className="shadow bg-gray-700 text-white px-3 py-1 rounded-lg border border-gray-400 hover:bg-gray-900" onClick={()=>setCDA(null)}>No</button>
              </div>
            </div>     
        </div>
        }
      <div className="rounded-full flex p-2 text-center bg-flip-blue mt-2"> 
        {/* <div onClick={()=>{IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 5)}} */}
        <div onClick={()=>{ChangePressureModeDicingThrottleValve()}}   //onClick={()=>{DicingThrottleValveAction(throttlePressureMode)}}
        className={`flex-1 rounded-l-full p-1 bg-flip-label  cursor-pointer ${DTDLState["DicingChamberThrottleValve"].Control_Mode==5 && 'bg-flip-label-active'} -mr-3  text-sm transition-all`}>Pressure</div>
        <div className="relative rounded-full  flex-1 bg-flip-btn cursor-pointer hover:scale-110 transition-all active:scale-100" 
        onClick={()=>modeFunction(DTDLState["DicingChamberThrottleValve"].Control_Mode)}>
          <div className={`rounded-full h-5 w-5  bg-cyan-400 border-2 shadow-inner shadow-gray-500  top-1 transition-all 
            ${DTDLState["DicingChamberThrottleValve"].Control_Mode==5 ? "left-1 absolute" :
              DTDLState["DicingChamberThrottleValve"].Control_Mode==2? "right-1 absolute":'mx-auto mt-1 opacity-50 bg-gray-300'}`}
            ></div> 
        </div>
        {/* <div  onClick={()=>{IOWrite('MAIN.DicingChamberThrottleValve.Out.Control_Mode', 2)}} */}
        <div onClick={()=>{ChangePositionModeDicingThrottleValve()}}  //onClick={()=>{DicingThrottleValveAction(throttlePositionMode)}}
        className={`flex-1 rounded-r-full p-1 bg-flip-label cursor-pointer ${DTDLState["DicingChamberThrottleValve"].Control_Mode==2 && 'bg-flip-label-active  '} -ml-3 text-sm transition-all`}>Position</div>
      </div>
      
      {DTDLState["DicingChamberThrottleValve"].Control_Mode==5?<>
        <div className="flex w-full mt-2 font-bold">
          <div className="flex-1 font-bold text-gray-600">Value </div>
          <div className="dysing-input rounded relative flex">
            <input type="text" value={pressure} onChange={(e)=>setPressure(e.target.value)} className="bg-transparent flex-1 w-40  p-1 px-2"/>
            <div className="text-right p-1 px-2 w-12">Torr</div>
          </div>
        </div>
        <div className="flex w-full mt-5 font-bold">
          <div className="flex-1 font-bold text-gray-600">Actual Pressure : <span>{actualPressure}</span> <span>Torr</span> </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mt-5">
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={()=>setModescreen(!modescreen)}>CANCEL</button>
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600"
          onClick={()=>setpressureValue()}>SET</button>
        </div>
      </>:
      DTDLState["DicingChamberThrottleValve"].Control_Mode==2?
      <>

        <div className="flex w-full mt-2 font-bold">
          <div className="flex-1 font-bold text-gray-600">Value  </div>
          <div className="dysing-input rounded relative flex">
            <input type="text" value={position} onChange={(e)=>setPosition(e.target.value)} className="bg-transparent flex-1 w-40  p-1 px-2"/><div className="text-right p-1 px-2 w-12">%</div>
          </div>
        </div>
        <div className="flex w-full mt-2 font-bold">
          <div className="flex-1 w-full font-bold text-gray-600">Actual Position : <span>{actualPosition} </span><span>%</span> </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mt-2">
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-3 font-bold py-3 rounded-lg  !text-gray-600" onClick={()=>setModescreen(!modescreen)}>CANCEL</button>
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-3 font-bold py-3 rounded-lg  !text-gray-600"
            onClick={()=>setPositionValue()}>SET</button>
        </div>
      
        </>:
         <div className="grid grid-cols-2 gap-3 mt-2">
         <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-3 font-bold py-3 rounded-lg  !text-gray-600" onClick={()=>setModescreen(!modescreen)}>CANCEL</button>
         
       </div>

        }
        {DTDLState["DicingChamberThrottleValve"].Error_Number==0 &&
          <div className="text-red-500 text-sm bg-red-100 mt-3 rounded-md shadow-lg p-2">
            <div>Error Number: {DTDLState["DicingChamberThrottleValve"].Error_Number}</div>
            <div>General Status: {DTDLState["DicingChamberThrottleValve"].General_Status}</div>
            <div>General Warnings: {DTDLState["DicingChamberThrottleValve"].General_Warnings}</div>
            <div>Extended Warnings: {DTDLState["DicingChamberThrottleValve"].Extended_Warnings}</div>
          </div>
        }
    </div>

  </>);
}