import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService, ObjectService, ObjectSubscriptionService } from "@getvia/sdk";

import { getAllChambers, getAllRecipesByChamber, getAllSequences } from "../../api";

import DicingBiasPoverModel from "./dicing/dicingBiasPoverModel";
import IsotopicGasbox from "./iec/isotopicGasbox";
import IsotopicETCHChamber from "./iec/isotopicETCHChamber";
import IsotopicMoterModel from "./iec/isotopicMoterModel";
import IsotopicPressureMode from "./iec/isotopicPressureMode";
import IsotopicGasboxDTDL from "./iec/isotopicGasboxDTDL";
import IsotopicETCHChamberDTDL from "./iec/isotopicETCHChamberDTDL";

const objectId = ""; //TODO: make it configurable

const viaConfigChannel = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const viaConfigobject = {
  objectServiceOptions: {
    apiToken: 'test',
    baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
    wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB
  }
};
const objectService = new ObjectService(viaConfigobject.objectServiceOptions);
const objectSubscriptionService = new ObjectSubscriptionService(viaConfigobject.objectServiceOptions);

const channelService = new ChannelService(viaConfigChannel.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfigChannel.channelServiceOptions);
const defaultObjectChannels = [
  {
    object: "MFC",
    name: "MFC1",
    readChannelName: "MAIN.MFC1.In.Flow_Reading",
    writeChannelName: "MAIN.MFC1.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC1.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC1out",
    feedBackChannel: "MAIN.MFC1.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC2",
    readChannelName: "MAIN.MFC2.In.Flow_Reading",
    writeChannelName: "MAIN.MFC2.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC2.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC2out",
    feedBackChannel: "MAIN.MFC2.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC3",
    readChannelName: "MAIN.MFC3.In.Flow_Reading",
    writeChannelName: "MAIN.MFC3.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC3.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC3out",
    feedBackChannel: "MAIN.MFC3.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC4",
    readChannelName: "MAIN.MFC4.In.Flow_Reading",
    writeChannelName: "MAIN.MFC4.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC4.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC4out",
    feedBackChannel: "MAIN.MFC4.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC5",
    readChannelName: "MAIN.MFC5.In.Flow_Reading",
    writeChannelName: "MAIN.MFC5.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC5.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC5out",
    feedBackChannel: "MAIN.MFC5.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC6",
    readChannelName: "MAIN.MFC6.In.Flow_Reading",
    writeChannelName: "MAIN.MFC6.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC6.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC6out",
    feedBackChannel: "MAIN.MFC6.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "DownValve",
    name: "DownStreamValve1",
    readChannelName: "MAIN.MFC1_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC1_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC1_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DownStreamValve2",
    readChannelName: "MAIN.MFC2_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC2_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC2_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DownStreamValve3",
    readChannelName: "MAIN.MFC3_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC3_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC3_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DownStreamValve4",
    readChannelName: "MAIN.MFC4_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC4_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC4_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DownStreamValve5",
    readChannelName: "MAIN.MFC5_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC5_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC5_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DownStreamValve6",
    readChannelName: "MAIN.MFC6_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC6_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC6_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UpStreamValve1",
    readChannelName: "MAIN.MFC1_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC1_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC1_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UpStreamValve2",
    readChannelName: "MAIN.MFC2_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC2_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC2_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UpStreamValve3",
    readChannelName: "MAIN.MFC3_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC3_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC3_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UpStreamValve4",
    readChannelName: "MAIN.MFC4_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC4_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC4_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UpStreamValve5",
    readChannelName: "MAIN.MFC5_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC5_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC5_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UpStreamValve6",
    readChannelName: "MAIN.MFC6_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC6_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC6_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },



  {
    object: "ISOChmbrPrGauge",
    name: "ISOChmbrPrGauge",
    feedBackChannel: "MAIN.ISOChmbrPrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOChmbrBr10TorrGauge",
    name: "ISOChmbrBr10TorrGauge",
    feedBackChannel: "MAIN.ISOChmbrBr10TorrGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "ISOPiraniGuage",
    name: "ISOPiraniGuage",
    feedBackChannel: "MAIN.ISOPiraniGuage.In.Sensor_Value",
    currentValue: false,
  }, 
  {
    object: "ISOChmbrBrPiraniGauge",
    name: "ISOChmbrBrPiraniGauge",
    feedBackChannel: "MAIN.ISOChmbrBrPiraniGauge.In.Sensor_Value_Module_1",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm1",
    feedBackChannel: "MAIN.Foreline_Pmp.In.Alarm_fbk",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm2",
    feedBackChannel: "MFC1_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm3",
    feedBackChannel: "MFC2_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm4",
    feedBackChannel: "MFC3_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm5",
    feedBackChannel: "MFC4_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm",
    feedBackChannel: "Global",
    currentValue: false,
  },
  {
    object: "MFC_SEQ_Cmd",
    name: "MFC_SEQ_Cmd",
    feedBackChannel: "MFC_SEQ_Cmd",
    currentValue: null,
  },
  {
    object: "MFC_SEQ_Name",
    name: "MFC_SEQ_Name",
    feedBackChannel: "MFC_SEQ_Name",
    currentValue: null,
  },
  {
    object: "PM1",
    name: "PM1.WaferID",
    feedBackChannel: "PM1.WaferID",
    currentValue: "",
  },
  {
    object: "Sequence",
    name: "ISOEtchRecipeSequence_StepNum",
    feedBackChannel: "ISOEtchRecipeSequence_StepNum",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "ISOEtchRecipeSequence_StepTime",
    feedBackChannel: "ISOEtchRecipeSequence_StepTime",
    currentValue: null,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply.In.Actual_Forward_Power",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.Actual_Forward_Power", //NOT CORRECT
    currentValue: null,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply.In.Actual_Reflected_Power",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.Actual_Reflected_Power",//NOT CORRECT
    currentValue: null,
  },
  {
    object: "ISOBias_Power",
    name: "Bias_Power.In.Actual_Forward_Power",
    feedBackChannel: "MAIN.ISOBias_Power.In.Actual_Forward_Power",
    currentValue: null,
  },
  {
    object: "ISOBias_Power",
    name: "Bias_Power.In.Actual_Reflected_Power",
    feedBackChannel: "MAIN.ISOBias_Power.In.Actual_Reflected_Power",
    currentValue: null,
  },
  {
    object: "RPSPowerSupply",
    name: "RPSPowerSupply",
    feedBackChannel: "MAIN.RPSPowerSupply.In.Actual_Setpoint",
    writeChannelName: "MAIN.RPSPowerSupply.Out.Setpoint",
    currentValue: 1,
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChuck_Motor_Config_Process_Position',
    readChannelName: 'DicingChuck_Motor_Config_Process_Position',
    writeChannelName: 'DicingChuck_Motor_Config_Process_Position',
    feedBackChannel: 'DicingChuck_Motor_Config_Process_Position',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChuck_Motor_Config_Transfer_Position',
    readChannelName: 'DicingChuck_Motor_Config_Transfer_Position',
    writeChannelName: 'DicingChuck_Motor_Config_Transfer_Position',
    feedBackChannel: 'DicingChuck_Motor_Config_Transfer_Position',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Actual_motor_position',
    readChannelName: 'MAIN.DicingChamberServoMotor.In.Actual_motor_position',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.In.Actual_motor_position',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Actual_motor_velocity',
    readChannelName: 'MAIN.DicingChamberServoMotor.In.Actual_motor_velocity',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.In.Actual_motor_velocity',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChamberServoMotor',
    readChannelName: 'MAIN.DicingChamberServoMotor.Out.Control_word',
    writeChannelName: 'MAIN.DicingChamberServoMotor.Out.Control_word',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.Out.Control_word',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorServoOnSeq',
    readChannelName: 'Status_DicingChuckMotorServoOnSeq',
    writeChannelName: 'Status_DicingChuckMotorServoOnSeq',
    feedBackChannel: 'Status_DicingChuckMotorServoOnSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorHomeSeq',
    readChannelName: 'Status_DicingChuckMotorHomeSeq',
    feedBackChannel: 'Status_DicingChuckMotorHomeSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorProcessPosSeq',
    readChannelName: 'Status_DicingChuckMotorProcessPosSeq',
    feedBackChannel: 'Status_DicingChuckMotorProcessPosSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorTransferPosSeq',
    readChannelName: 'Status_DicingChuckMotorTransferPosSeq',
    feedBackChannel: 'Status_DicingChuckMotorTransferPosSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChamberServoMotorTarget_velocity',
    readChannelName: 'MAIN.DicingChamberServoMotor.Out.Target_velocity',
    writeChannelName: 'MAIN.DicingChamberServoMotor.Out.Target_velocity',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.Out.Target_velocity',
    currentValue: null
  },
  {
    object: 'DicingChamberManometer',
    name: 'DicingChamberManometer1',
    readChannelName: 'MAIN.DicingChamberManometer.In.Sensor_Value',
    writeChannelName: 'MAIN.DicingChamberManometer.In.Sensor_Value',
    feedBackChannel: 'MAIN.DicingChamberManometer.In.Sensor_Value',
    currentValue: null
  },
  {
    object: 'DicingChamberPiraniGuage',
    name: 'DicingChamberPiraniGuage1',
    readChannelName: 'MAIN.DicingChamberPiraniGuage.In.Sensor_Value',
    writeChannelName: 'MAIN.DicingChamberPiraniGuage.In.Sensor_Value',
    feedBackChannel: 'MAIN.DicingChamberPiraniGuage.In.Sensor_Value',
    currentValue: null
  },
  {
    object: 'DicingChamberPumpPiraniGuage',
    name: 'DicingChamberPumpPiraniGuage',
    readChannelName: 'MAIN.DicingChamberPumpPiraniGuage.In.Sensor_Value',
    writeChannelName: 'MAIN.DicingChamberPumpPiraniGuage.In.Sensor_Value',
    feedBackChannel: 'MAIN.DicingChamberPumpPiraniGuage.In.Sensor_Value',
    currentValue: null
  },
  {
    object: 'ISOThrottleValve',
    name: 'ISOThrottleValveControlMode',
    readChannelName: 'MAIN.ISOThrottleValve.In.Control_Mode', //to be changed to in channel
    writeChannelName: 'MAIN.ISOThrottleValve.Out.Control_ModeThrottleValve.Out.Control_Mode',
    feedBackChannel: 'MAIN.ISOThrottleValve.In.Control_Mode', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOThrottleValve',
    name: 'ISOThrottleValve',
    readChannelName: 'MAIN.ISOThrottleValve.In.Actual_Pressure', //to be changed to in channel
    writeChannelName: 'MAIN.ISOThrottleValve.Out.Target_Pressure',
    feedBackChannel: 'MAIN.ISOThrottleValve.In.Actual_Pressure', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOThrottleValve',
    name: 'ISOThrottleValveActual_Position',
    readChannelName: 'MAIN.ISOThrottleValve.In.Actual_Position', //to be changed to in channel
    writeChannelName: 'MAIN.ISOThrottleValve.Out.Target_Position',
    feedBackChannel: 'MAIN.ISOThrottleValve.In.Actual_Position', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberTurbo_Pump',
    name: 'DicingChamberTurbo_PumpSpeed',
    readChannelName: 'MAIN.DicingChamberTurbo_Pump.In.Turbo_Speed', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberTurbo_Pump.In.Turbo_Speed',
    feedBackChannel: 'MAIN.DicingChamberTurbo_Pump.In.Turbo_Speed', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberTurbo_Pump',
    name: 'DicingChamberTurbo_PumpStatus',
    readChannelName: 'MAIN.DicingChamberTurbo_Pump.In.Rotation', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberTurbo_Pump.Out.Turbo_Start_Stop',
    feedBackChannel: 'MAIN.DicingChamberTurbo_Pump.In.Rotation', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'RPSPowerSupply',
    name: 'RPSPowerSupply',
    readChannelName: 'MAIN.RPSPowerSupply.In.RF_ON_Indication', 
    writeChannelName: 'MAIN.RPSPowerSupply.Out.RF_ON_Request',
    feedBackChannel: 'MAIN.RPSPowerSupply.In.RF_ON_Indication',
    currentValue: false
  },
  {
    object: 'RPSPowerSupply',
    name: 'RPSPowerSupplySetpoint',
    readChannelName: 'MAIN.RPSPowerSupply.Out.Setpoint', 
    // readChannelName: 'MAIN.DicingChamberSource_Power.In.Actual_Setpoint', 
    writeChannelName: 'MAIN.RPSPowerSupply.Out.Setpoint',
    feedBackChannel: 'MAIN.RPSPowerSupply.Out.Setpoint', 
    // feedBackChannel: 'MAIN.DicingChamberSource_Power.In.Actual_Setpoint', 
    currentValue: null
  },
  {
    object: 'ISOBias_Power',
    name: 'ISOBias_Power',
    readChannelName: 'MAIN.ISOBias_Power.In.RF_ON_Indication',
    writeChannelName: 'MAIN.ISOBias_Power.Out.RF_ON_Request',
    feedBackChannel: 'MAIN.ISOBias_Power.In.RF_ON_Indication', 
    currentValue: null
  },
  {
    object: 'ISOBias_Power',
    name: 'ISOBias_PowerSetpoint',
    readChannelName: 'MAIN.ISOBias_Power.Out.Setpoint', 
    // readChannelName: 'MAIN.DicingChamberBias_Power.In.Actual_Setpoint', 
    writeChannelName: 'MAIN.ISOBias_Power.Out.Setpoint',
    // feedBackChannel: 'MAIN.DicingChamberBias_Power.In.Actual_Setpoint', 
    feedBackChannel: 'MAIN.ISOBias_Power.Out.Setpoint', 
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Sequence',
    readChannelName: 'Status_ISOEtchRecipeSequence', //to be changed to in channel
    writeChannelName: 'Status_ISOEtchRecipeSequence',
    feedBackChannel: 'Status_ISOEtchRecipeSequence', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOSlit_Valve',
    name: 'ISOSlit_ValveClose',
    readChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Close', //to be changed to in channel
    writeChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Close',
    feedBackChannel: 'MAIN.ISOSlit_Valve.Out.Slit_Close', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'ISOSlit_Valve',
    name: 'ISOSlit_ValveOpen',
    readChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Open', //to be changed to in channel
    writeChannelName: 'MAIN.ISOSlit_Valve.Out.Slit_Open',
    feedBackChannel: 'MAIN.ISOSlit_Valve.Out.Slit_Open', //to be changed to in channel
    currentValue: null
  },
  // {
  //   object: 'DicingChamberManoIsolation_Valve',
  //   name: 'DicingChamberManoIsolation_Valve',
  //   readChannelName: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd', //to be changed to in channel
  //   writeChannelName: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd',
  //   feedBackChannel: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd', //to be changed to in channel
  //   currentValue: null
  // },
  {
    object: 'ISON2VentValve',
    name: 'ISON2VentValve',
    readChannelName: 'MAIN.ISON2VentValve.In.Open_fbk', //to be changed to in channel
    writeChannelName: 'MAIN.ISON2VentValve.Out.Open_cmd',
    feedBackChannel: 'MAIN.ISON2VentValve.In.Open_fbk', //to be changed to in channel
    currentValue: null
  },
];

const defaultObjectDTDL = {
  "RPSPowerSupply":{
    "RF_ON_Indication":false, //SetRFONRequest  
    "Actual_Setpoint":false, //Setpoint  
    "Actual_Delivered_Power":false, 
  },


  
  "DicingChamberPiraniGuage": {
    "Sensor_Value": 0
  },
  "DicingChamberManometer": {
    "Sensor_Value": 0
  },
  "DicingChamberPumpPiraniGuage": {
    "Sensor_Value": 0
  },
  "DicingChamberSlit_Valve": {
    "OpenValve": 0,
    "CloseValve":0
  },
  "DicingChamberTurbo_Pump": {
    "Turbo_Speed": 0
  },
  "DicingChamberSource_Power": {
    "RF_ON_Indication":false,
    "Actual_Reflected_Power": 0,
    "Actual_Forward_Power": 0,
    "Actual_Setpoint": 0,
  },
  "ISOBias_Power":{
    "RF_ON_Indication":false,
    "Actual_Reflected_Power": 0,
    "Actual_Forward_Power": 0,
    "Actual_Setpoint": 0,
  },
  "DicingChamberManoIsolation_Valve": {
    "OpenCloseValve":  0
  },
  "DicingM7Status":{
    "DicingSlitValveCloseStatus": false,
    "DicingSlitValveOpenStatus": false,
    "Lid_Switch_Top_Status_OSSD2": false,
    "Lid_Switch_Top_Status_OSSD1": false,
    "Lid_Switch_Bottom_Status_OSSD2": false,
    "Lid_Switch_Bottom_Status_OSSD1": false,
    "DicingChamberVentValveOpenStatus": false,
    "DicingChamberTurboIsolationValveOpenStatus": false,
    "DicingChamberManometerIsolationValveOpenStatus": false,
    "DicingChamberRoughValveOpenStatus": false,
    "DicingChamberRoughValveCloseStatus": false,
    "DicingChamberRoughPumpStatusFB": false
  }
}


export default function IsotopicEtchChamberDTDL() {


 const [motorscreen,setMotorscreen] = useState(false)
 const [modescreen,setModescreen] = useState(false)
 const [sourcescreen,setSourcescreen] = useState(false)
 const [biasscreen,setBiasscreen] = useState(false)

  const [time, settime] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [sequences, setSequences] = useState([]);
  const [loopTestSequences, setLoopTestSequences] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [instanceId, setInstanceId] = useState("");
  const [runningStatus, setRunningStatus] = useState("");
  const [currLoop, setCurrLoop] = useState(0);

  
  const [forwardModel, setForwardModel] = useState(null);
  const [forwardvalue, setForwardvalue] = useState(null);

  let execIntervalId = 0;

// -------------------- CHANNEL IMPLIMENTATION----------------START--/
const gasBoxConfiguration = useRef(defaultObjectChannels);
const channels = useRef();
const subscriptionRef = useRef();
const [gasBoxState, setGasBoxState] = useState();

const fetchChannelsStates = async () => {
  const response = await channelService.ChannelStates();
  console.log("ChannelStates: ", { response });
  if (response) {
      channels.current = response;
      updateGasBoxStateChannel(channels.current);
  }
};
const updateGasBoxStateChannel = (updatedChannels) => {
  let index = -1
  let gasBoxConfigurationState = gasBoxConfiguration.current;
  updatedChannels.forEach((item) => {
      const config = gasBoxConfigurationState.find((obj, objIndex) => {
      index = objIndex;
      return obj.feedBackChannel === item.channelName
      })
      if (config) {
      config.currentValue = item.data.value;
      gasBoxConfigurationState[index] = config;
      }
  })
  setGasBoxState(() => gasBoxConfigurationState);
}
const subscribeToNotificationChannel = () => {
  const onNewMessage = (message) => {
      const channel = JSON.parse(message);
      updateGasBoxStateChannel([channel])
  };
  let channelSubscriptions = [];
  gasBoxConfiguration.current.forEach(config => {
      channelSubscriptions.push(config.feedBackChannel);
  })
  const subscription = {
      channels: channelSubscriptions,
      onNewMessage: onNewMessage,
  };
  subscriptionRef.current = subscription;
  channelSubscriptionService.subscribe(subscription);
};
const unSubscribe = () => {
  channelSubscriptionService.unSubscribe(subscriptionRef.current);
};
const IOWrite = (channelName, value) => {
console.log("Channels: ", { channels });
console.log("IOWrite: ", { channelName, value });
if (channelName) {
  const channel = channels.current?.find(obj => {
    return obj.channelName === channelName
  })
  if (channel) {
    channel.data.value = value;
    channelService.Write(channel).then(() => {
    });
  }
}
}

useEffect(() => {
  const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotificationChannel();
  };
  fetChannelData();
  return () => {
      unSubscribe();
  };
}, []);
const handleforward = () => {
if(forwardModel=='source') {
  IOWrite('MAIN.DicingChamberSource_Power.Out.Setpoint', forwardvalue);    
} else if(forwardModel=='bias') {
  IOWrite('MAIN.DicingChamberBias_Power.Out.Setpoint', forwardvalue);   
}
setForwardModel(null)
}
const SourcemodeFunction = async (e) => {
let val = !e;
IOWrite('MAIN.DicingChamberSource_Power.Out.RF_ON_Request', val);
console.log(val)
}
const BiasmodeFunction = async (e) => {
let val = !e;
IOWrite('MAIN.DicingChamberBias_Power.Out.RF_ON_Request', val);
console.log(val)
}
// -------------------- CHANNEL IMPLIMENTATION----------------END--//
// -------------------- DTDL IMPLIMENTATION----------------START--//
const gasBoxConfigurationDTDL = useRef();
const subscriptionRefDTDL = useRef();
const [DTDLState, setDTDLState] = useState(defaultObjectDTDL);
function flattenDigitalTwinsByName(data) {
  const flattened = {};
  data.digitalTwins.forEach(twin => {
    // console.log('twin',twin)
    flattened[twin.Name] = twin;
  });
  return flattened;
}
const fetchObjectState = async () => {
  const response = await objectService.GetObjectGraphById(objectId)

  if (response) {
    gasBoxConfigurationDTDL.current = response;
    var flattenObject = flattenDigitalTwinsByName(response);
    setDTDLState(() => flattenObject);
    console.log(response,'flattenObject')
  }
};
const updateGasBoxStateDTDL = (object) => {

  gasBoxConfiguration.current.digitalTwins = gasBoxConfigurationDTDL.current.digitalTwins.map(dt => {
    if (dt.$dtId === object.$dtId) {
      const updatedObject = { ...dt, ...object};
      return updatedObject;
    }
    return dt;
  });
  var flattenObject = flattenDigitalTwinsByName(gasBoxConfigurationDTDL.current);
  setDTDLState(() => flattenObject);

}
const subscribeToNotificationDTDL = () => {
  const onNewMessageDTDL = (message) => {
    const object = JSON.parse(message);
    updateGasBoxStateDTDL(object)
  };
  const objectSubscriptions = gasBoxConfigurationDTDL.current.digitalTwins.map(fdt => fdt.$dtId);
  const subscription = {
    channels: objectSubscriptions,
    onNewMessage: onNewMessageDTDL,
  };
  subscriptionRefDTDL.current = subscription;
  objectSubscriptionService.subscribeObjects(subscription);
};
const unSubscribeDTDL = () => {
  objectSubscriptionService.unSubscribeObjects(subscriptionRefDTDL.current);
};
useEffect(() => {
  const fetchState = async () => {
    await fetchObjectState();
    subscribeToNotificationDTDL();
  };
  fetchState();
  return () => {
    unSubscribeDTDL();
  };
}, []); 
// -------------------- DTDL IMPLIMENTATION----------------END--//


















const getRunningStatusTestExec = async (currInstanceId) => {
  await fetch(
    process.env.REACT_APP_VIA_RULE_ORCHES_API +
      "/api/Sequence/" +
      currInstanceId,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
    }
  )
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Could not get execution status.");
      }
      // Parse the response as JSON
      return response.json();
    })
    .then((data) => {
      // Handle the JSON data
      let runStatus = data.runtimeStatus;

      setRunningStatus(() => runStatus);
      if (
        runStatus == "TERMINATED" ||
        runStatus == "COMPLETED" ||
        runStatus == "FAILED"
      ) {
        clearInterval(execIntervalId);
        execIntervalId = 0;
        setInstanceId(()=>'');
        localStorage.removeItem("isoEtchChbrLoopInstId")
      }
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error get running status:", error);
    });
};




  useEffect(() => {
    const interval = setInterval(() => {
      settime(new Date())
    }, 1000);
  
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const getRecipes = async () => {
    const chambers = await getAllChambers()
    if(chambers){
      const chamber = chambers?.filter((x)=> x.chamberName == 'Isotropic Etch Chamber')[0]
      const recipes = await getAllRecipesByChamber(chamber.id);
      if (recipes) {
        setRecipes(recipes.DATA);
      }
    }
  };
  const getSequencelist = async () => {
    const sequences = await getAllSequences();
    console.log('iso sequence',sequences);
    if(sequences && sequences.length>0){
      const filteredByRecipe = sequences.filter(sequence => sequence.sequenceType === "RecipeSequence"); 
      setSequences(filteredByRecipe)
      const selected = sequences.filter((sequence) => sequence.sequenceName === "ISOEtchRecipeSequence");
        setSelectedSequence(selected[0]);
      const filteredByLoopTest = sequences.filter(sequence => sequence.sequenceType === "LoopSequence"); 
      setLoopTestSequences(filteredByLoopTest)
    }
  }
  const fetchRunningStatus = ()=>{
    const currInstanceId = localStorage.getItem('isoEtchChbrLoopInstId');
    const selStoredDicingRecipe = JSON.parse(localStorage.getItem('selStoredRecipe'));
    if(selStoredDicingRecipe && selStoredDicingRecipe.id)
      setSelectedRecipe(()=> selStoredDicingRecipe)
    if(currInstanceId && currInstanceId!=""){
      setInstanceId(()=> currInstanceId)      
        // execIntervalId = setInterval(()=>{
        //   getRunningStatusTestExec(currInstanceId);
        // },1000);
      }
  }
  const executeRecipe = async () => {debugger
    if(!selectedRecipe || !selectedRecipe.id){
      return;
    }
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: selectedSequence?.sequenceName,
      inputData: {
        RecipeId: selectedRecipe.id,
      },
    };
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(formdata),
      }
    )
      .then((response) => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Could not start execution.");
        }
        // Parse the response as JSON
        return response.json();
      })
      .then((data) => {
        // Handle the JSON data
        let currInstanceId = data[0].resultValue;
        setInstanceId(() => currInstanceId);
        localStorage.setItem('isoEtchChbrLoopInstId',currInstanceId);
        localStorage.setItem('selStoredRecipe',JSON.stringify(selectedRecipe));
        // execIntervalId = setInterval(() => {
        //   getRunningStatusTestExec(currInstanceId);
        // }, 1000);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error start execution:", error);
      });
  };
  const stopTestExec = async (e) => {
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API +
        "/api/Sequence/terminate?instanceId=" +
        instanceId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
      }
  ).then(response => {
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Could not stop execution.');
    }
    // Parse the response as JSON
    return response.json();
  })
  .then(data => {
    // Handle the JSON data
    if(data.statusCode==200){
      closingRecipe();
      localStorage.removeItem('isoEtchChbrLoopInstId')
      clearInterval(execIntervalId)
    } else{
    }
    
  })
  .catch(error => {
    // Handle any errors that occurred during the fetch
    console.error('Fetch error in stop execution:', error);
  });
    };
    const closingRecipe = async (e) => {
      var formdata = {
        token: "",
        ruleType: "Sequence",
        ruleGroups: [],
        ruleSubGroups: [],
        rule: "",
        sequence: "ISOEtchClosingSequence",
        inputData: {},
      };
      // Convert data to JSON string
      try {
        const response = await fetch(
          process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Add any other headers as needed
            },
            body: JSON.stringify(formdata),
          }
        );
  
        if (response.ok) {
          // Handle success
          console.log("Closing Recipe Ran!");
          IOWrite('Status_ISOEtchRecipeSequence', 'TERMINATED');
        } else {
          // Handle error
          console.error("Failed to post data in closing recipe.");
        }
      } catch (error) {
        console.error("Error in closing recipe:", error);
      }
    };

    const handleRecipe = (e) => {
      const val = e.target.value;debugger
      if (val) {
        const selected = recipes.filter((recipe) => recipe.id == e.target.value);
        if(selected[0] && selected[0].id){
          setSelectedRecipe(selected[0]);
          localStorage.setItem('selStoredRecipe',JSON.stringify(selected[0]));
        }else{
          setSelectedRecipe({});
        }
      } else {
        setSelectedRecipe({});
      }
    };


  useEffect(() => {
    getSequencelist();
    getRecipes();
    fetchRunningStatus();
  }, []);
  return (<>
    <div className="flex gap-2 w-full h-screen p-1 dysingcontainer-bg overflow-y-auto">
      <div className="flex flex-1 bg-blue-100 p-3 rounded-md">{time?.toString}
        
        <div className="w-80">
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-4">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Process Monitor</div>
            <div className="relative w-full h-6 -mt-4">
              <div className="float-right w-10 bg-green-500 h-3 absolute right-1 rounded-full"></div>
            </div>
            <div className="grid grid-cols-3 gap-2 text-xs  font-semibold">
              <div class="bg-blue-200 p-2 flex-1 rounded- dysing-input">{gasBoxState?.find(o => o.name === "PM1.WaferID").currentValue }</div>
              <div class="bg-blue-200 p-2 flex-1 rounded-sm  dysing-input ">Step : {gasBoxState?.find(o => o.name === "ISOEtchRecipeSequence_StepNum").currentValue }</div>
              <div class="bg-blue-200 p-2 flex-1 rounded-sm  dysing-input ">{gasBoxState?.find(o => o.name === "ISOEtchRecipeSequence_StepTime").currentValue } Sec</div>
            </div>
            <div className="mb-4">
              <div class=" flex gap-2 mt-4 text-sm">Process Step</div>
              <div className="flex gap-3 mt-2">
                <select className="text-sm flex-1 bg-gray-200 px-2 py-1  dysing-bg-gray rounded font-semibold text-gray-600"
                 onChange={handleRecipe} >
                  <option>Select Recipe</option>
                    {recipes && recipes.length>0 && 
                      recipes.map((recipe, i) => 
                      <option key={recipe.id+i+"recipe-list"} value={recipe.id}>{recipe.recipeName}</option>
                    )}
                </select>
                {/* {!instanceId ? (   */}
                  
                {gasBoxState?.find(o => o.name === "Sequence").currentValue!='RUNNING' ? (  
                  <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none w-20"  
                    onClick={() => executeRecipe()}>
                    RUN
                  </button>
                ) : 
                <button
                  onClick={() => stopTestExec()}
                  className="dysing-btn-bg py-1 shadow-md rounded hover:shadow-none w-20"
                >
                  Abort
                </button>
                
                }
              </div>
              {/* <div className="mt-3">
               {gasBoxState?.find(o => o.name === "Sequence").currentValue!='RUNNING' ?  
                <span>Completed recipe: {selectedRecipe.recipeName}</span> 
              :
                <span>Executing recipe: {selectedRecipe.recipeName}</span>
              }
              </div> */}
            </div>

          </div>
          
          {/* <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none w-20 mt-3" onClick={() => setMotorscreen(!motorscreen)}>Motor</button> */}
        </div>
        <div className="flex-1 text-center px-1">
          
          <IsotopicETCHChamberDTDL gasBoxState={gasBoxState} IOWrite={IOWrite} setMotorscreen={setMotorscreen} setModescreen={setModescreen}/>
        </div>
        <div className="">
          <IsotopicGasboxDTDL gasBoxState={gasBoxState}/>

          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1" 
            onClick={() => setSourcescreen(!sourcescreen)}
            >RPS Power</div>
            {/* RPS POWER SUPLY */}
            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
              onClick={()=>SourcemodeFunction(DTDLState["RPSPowerSupply"].RF_ON_Indication)}>  
                  {/* {gasBoxState?.find(o => o.writeChannelName === "MAIN.RPSPowerSupply.Out.RF_ON_Request").currentValue} */}
                <div className="px-2 text-sm font-semibold">{DTDLState["RPSPowerSupply"].RF_ON_Indication===true ? "ON" : "OFF"}</div>
                <div className={`h-5 w-5 rounded-full ${DTDLState["RPSPowerSupply"].RF_ON_Indication===true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
             </div>
            <div className="grid grid-cols-2 mb-3 gap-2  font-bold text-gray-600 relative">
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1  w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow">
                  <div class="relative z-20 cursor-pointer" onClick={()=>{setForwardModel('source');setForwardvalue(DTDLState["RPSPowerSupply"].Actual_Setpoint )}}>	
                    {/* {gasBoxState?.find(o => o.name === "RPSPowerSupplySetpoint").currentValue } */}
                    {DTDLState["RPSPowerSupply"].Actual_Setpoint}
                  </div>
                </div>
              </div>
              
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1 w-20">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20"> {DTDLState["RPSPowerSupply"].Actual_Delivered_Power}
                    {/* {gasBoxState?.find(o => o.name === "RPSPowerSupply.In.Actual_Reflected_Power").currentValue} */}

                  </div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-20">C1</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">0</div>
                </div>
              </div>

              <div className="flex">                
                <div class="gap-2 mt-1 w-20 text-center">C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              {forwardModel == 'source' &&
                  <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                    <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                      <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' 
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                     <div className="flex mt-2 rounded-xl overflow-hidden">
                        <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)} 
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                        <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' 
                        onClick={() => handleforward()}>Update</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1"  
            // onClick={() => setBiasscreen(!biasscreen)}
            >Bias Power</div>
            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
              onClick={()=>BiasmodeFunction(DTDLState["ISOBias_Power"].RF_ON_Indication)}>  
                <div className="px-2 text-sm font-semibold">{DTDLState["ISOBias_Power"].RF_ON_Indication==true ? "ON" : "OFF"}</div>
                <div className={`h-5 w-5 rounded-full ${DTDLState["ISOBias_Power"].RF_ON_Indication==true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
             </div>
            <div className="grid grid-cols-2 mb-3 gap-2 font-bold text-gray-600 relative">
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1 w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow ">
                  <div class="relative z-20"  onClick={()=>{setForwardModel('bias');setForwardvalue(DTDLState["ISOBias_Power"].Actual_Setpoint)}}>
                    {/* {gasBoxState?.find(o => o.name === "ISOBias_PowerSetpoint").currentValue } */}
                    {DTDLState["ISOBias_Power"].Actual_Setpoint}
                    </div>
                </div>
              </div>
              
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1 w-20">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">{DTDLState["ISOBias_Power"].Actual_Reflected_Power}</div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-20">C1</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-20 text-center">C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              {forwardModel == 'bias' &&
                  <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                    <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                      <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' 
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                     <div className="flex mt-2 rounded-xl overflow-hidden">
                        <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)} 
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                        <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' 
                        onClick={() => handleforward()}>Update</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="dysing-bg pt-3 px-2 rounded-md w-28"> 
        {/* <button className="border-4 border-gray-100 dysing-input-green px-2 py-6 text-xs font-bold w-full rounded-lg">ONLINE</button>
        <div className="mt-6 text-xs font-bold text-center">Commands</div> */}
        <div className=" ">
          <button className=" diceembossed-btn  py-3  !w-full mt-2 text-xs font-bold leading-8 rounded-lg !text-gray-600  ">ONLINE</button>
          <button className=" diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg !text-gray-600  ">PURGE</button>
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-sm font-bold leading-8 rounded-lg !text-gray-600  ">WATER</button>
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg !text-gray-600  ">LEAK RATE</button>
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg !text-gray-600  ">PUMPDOWN</button>
          <button className="diceembossed-btn  py-3 px-2 !w-full !h-16 !pt-3 mt-3 text-sm font-bold leading-5 rounded-lg !text-gray-600  ">CYCLE PURGE</button>
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg !text-gray-600  ">VENT</button>
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg !text-gray-600  " >STOP</button>             
        </div>

      </div>
    </div>
   {motorscreen&& 
    <div className="bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden z-30" >
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setMotorscreen(!motorscreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-4 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Motor</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setMotorscreen(!motorscreen)}>✖</button>
          </div>
        </div>     
        <IsotopicMoterModel gasBoxState={gasBoxState} IOWrite={IOWrite}/>        
      </div>
    </div>}
    {modescreen &&
    <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setModescreen(!modescreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-20 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Mode</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setModescreen(!modescreen)}>✖</button>
          </div>
        </div>     
        <IsotopicPressureMode gasBoxState={gasBoxState} IOWrite={IOWrite} setModescreen={setModescreen} modescreen={modescreen}/>        
      </div>
    </div>
    }
    {biasscreen &&
    <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setBiasscreen(!biasscreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Bias Power</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setBiasscreen(!biasscreen)}>✖</button>
          </div>
        </div>     
        <DicingBiasPoverModel gasBoxState={gasBoxState} IOWrite={IOWrite} setBiasscreen={setBiasscreen} biasscreen={biasscreen}/>        
      </div>
    </div>
    } 

</>  );
}