import { useEffect, useState } from "react";
import "./Pumpdown.css";
import { getAllChambers, getAllRecipes, getAllRecipesByChamber, getAllSequences } from "../../../api";

export default function Pumpdown({ isOn, channels, iecState }) {
  const [sequences, setSequences] = useState([]);
  const [loopTestSequences, setLoopTestSequences] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [selectedSequence, setSelectedSequence] = useState({});
  const [selectedNonRSequence, setSelectedNonRSequence] = useState({});
  const [instanceId, setInstanceId] = useState("");
  const [runningStatus, setRunningStatus] = useState("");
  const [currLoop, setCurrLoop] = useState(0);
  const alarmColor = isOn ? "#FB5046" : "#6B8DA1";
  let alarmChannels = [];
  channels.forEach((channelName) => {
    alarmChannels.push(<div>{channelName}</div>);
  });
  let execIntervalId = 0;

  const getRecipes = async () => {
    const chambers = await getAllChambers()
    if(chambers){debugger
      const chamber = chambers?.filter((x)=> x.chamberName == 'Isotropic Etch Chamber')[0]
      const recipes = await getAllRecipesByChamber(chamber.id);
      if (recipes) {
        setRecipes(recipes.DATA);
      }
    }
  };

  const getSequencelist = async () => {
    const sequences = await getAllSequences();
    if(sequences && sequences.length>0){
      const filteredByRecipe = sequences.filter(sequence => sequence.sequenceType === "RecipeSequence"); 
      setSequences(filteredByRecipe)
      const selected = sequences.filter((sequence) => sequence.sequenceName === "ISOEtchRecipeSequence");
        setSelectedSequence(selected[0]);
      const filteredByLoopTest = sequences.filter(sequence => sequence.sequenceType === "LoopSequence"); 
      setLoopTestSequences(filteredByLoopTest)
    }
  }

  const handlepumpdown = async (e) => {
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "Chamber_Service_Pump_Down",
      inputData: {},
    };
    // Convert data to JSON string
    try {
      const response = await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Alarm stopped");
      } else {
        // Handle error
        console.error("Failed to post data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handVent = async (e) => {
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "Chamber_Service_Vent",
      inputData: {},
    };
    // Convert data to JSON string
    try {
      const response = await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Alarm stopped");
      } else {
        // Handle error
        console.error("Failed to post data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleAlarm = async (e) => {
    var formdatab = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ResetAlarms",
      inputData: {},
    };
    // Convert data to JSON string
    try {
      const response = await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify(formdatab),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Alarm stopped");
      } else {
        // Handle error
        console.error("Failed to post data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRecipe = (e) => {
    const val = e.target.value;
    if (val) {
      const selected = recipes.filter((recipe) => recipe.id == e.target.value);
      setSelectedRecipe(selected[0]);
      localStorage.setItem('selStoredRecipe',JSON.stringify(selected[0]));
    } else {
      setSelectedRecipe({});
    }
  };

  const handleSequence = (e) => {
    const val = e.target.value;
    if (val) {
      const selected = sequences.filter((sequence) => sequence.id == val);
      setSelectedSequence(selected[0]);
    } else {
      setSelectedSequence({});
    }
  };

  const handleLoopTestSequence = (e) => {
    const val = e.target.value;
    if (val) {
      const selected = loopTestSequences.filter(
        (sequence) => sequence.id === val
      );
      setSelectedNonRSequence(selected[0]);
    } else {
      setSelectedNonRSequence({});
    }
  };

  const executeRecipe = async () => {
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: selectedSequence?.sequenceName,
      inputData: {
        RecipeId: selectedRecipe.id,
      },
    };
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(formdata),
      }
    )
      .then((response) => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Could not start execution.");
        }
        // Parse the response as JSON
        return response.json();
      })
      .then((data) => {
        // Handle the JSON data
        let currInstanceId = data[0].resultValue;
        setInstanceId(() => currInstanceId);
        localStorage.setItem('isoEtchChbrLoopInstId',currInstanceId);
        localStorage.setItem('selStoredRecipe',JSON.stringify(selectedRecipe));
        // execIntervalId = setInterval(() => {
        //   getRunningStatusTestExec(currInstanceId);
        // }, 1000);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error start execution:", error);
      });
  };

  const closingRecipe = async (e) => {
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: "ISOEtchClosingSequence",
      inputData: {},
    };
    // Convert data to JSON string
    try {
      const response = await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Closing Recipe Ran!");
      } else {
        // Handle error
        console.error("Failed to post data in closing recipe.");
      }
    } catch (error) {
      console.error("Error in closing recipe:", error);
    }
  };

  const updateLoopCnt = async (e) => {
    var channelData = {
      id: "ff45df9879868b5e98e4a079beff656c",
      externalID: 700,
      ioObject: "Sequence",
      channelName: "LoopCounter",
      channelLabel: "LoopCounter",
      driverName: "Memory",
      address: "",
      direction: "INOUT",
      unit: "",
      enum: "",
      scale: "",
      type: "INT",
      samplingPeriod: 0,
      data: {
        minValue: 0,
        maxValue: 0,
        initialValue: 0,
        value: currLoop,
      },
    };

    await fetch(process.env.REACT_APP_VIA_CORE_API + "/api/IO/write", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
      body: JSON.stringify(channelData),
    })
      .then((response) => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Could not update the Loop counter.");
        }
        return response;
      })
      .then((data) => {
        // Handle the JSON data
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error update loop counter:", error);
      });
  };
  const startTestExec = async (e) => {
    var formdata = {
      token: "",
      ruleType: "LoopSequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: selectedNonRSequence?.sequenceName,
      inputData: {},
    };
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(formdata),
      }
    )
      .then((response) => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Could not start execution.");
        }
        // Parse the response as JSON
        return response.json();
      })
      .then((data) => {
        // Handle the JSON data
        let currInstanceId = data[0].resultValue;
        setInstanceId(()=>currInstanceId);
        localStorage.setItem('isoEtchChbrLoopInstId',currInstanceId);
        // execIntervalId = setInterval(()=>{
        //   getRunningStatusTestExec(currInstanceId);
        // }, 1000);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error start execution:", error);
      });
  };
  // const getRunningStatusTestExec = async (currInstanceId) => {
  //   await fetch(
  //     process.env.REACT_APP_VIA_RULE_ORCHES_API +
  //       "/api/Sequence/" +
  //       currInstanceId,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // Add any other headers as needed
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       // Check if the request was successful
  //       if (!response.ok) {
  //         throw new Error("Could not get execution status.");
  //       }
  //       // Parse the response as JSON
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // Handle the JSON data
  //       let runStatus = data.runtimeStatus;

  //       setRunningStatus(() => runStatus);
  //       if (
  //         runStatus == "TERMINATED" ||
  //         runStatus == "COMPLETED" ||
  //         runStatus == "FAILED"
  //       ) {
  //         clearInterval(execIntervalId);
  //         execIntervalId = 0;
  //         setInstanceId(()=>'');
  //         localStorage.removeItem("isoEtchChbrLoopInstId")
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle any errors that occurred during the fetch
  //       console.error("Fetch error get running status:", error);
  //     });
  // };
  const stopTestExec = async (e) => {
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API +
        "/api/Sequence/terminate?instanceId=" +
        instanceId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
      }
  ).then(response => {
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Could not stop execution.');
    }
    // Parse the response as JSON
    return response.json();
  })
  .then(data => {
    // Handle the JSON data
    if(data.statusCode==200){
      closingRecipe();
      localStorage.removeItem('isoEtchChbrLoopInstId')
      clearInterval(execIntervalId)
    } else{
    }
    
  })
  .catch(error => {
    // Handle any errors that occurred during the fetch
    console.error('Fetch error in stop execution:', error);
  });
    };

  const fetchRunningStatus = ()=>{
    const currInstanceId = localStorage.getItem('isoEtchChbrLoopInstId');
    const selStoredRecipe = JSON.parse(localStorage.getItem('selStoredRecipe'));
    if(selStoredRecipe && selStoredRecipe.id)
      setSelectedRecipe(()=> selStoredRecipe)
    if(currInstanceId && currInstanceId!=""){
      setInstanceId(()=> currInstanceId)      
        // execIntervalId = setInterval(()=>{
        //   getRunningStatusTestExec(currInstanceId);
        // },1000);
      }
  }

  useEffect(() => {
    getSequencelist();
    getRecipes();
    fetchRunningStatus();
  }, []);

  useEffect(() => {
  }, [instanceId]);

  useEffect(() => {
    let newCurrLoop = iecState?.find(
      (o) => o.name === "SequenceLoopCounter"
    ).currentValue;
    setCurrLoop(() => newCurrLoop);
  }, [iecState?.find((o) => o.name === "SequenceLoopCounter").currentValue]);
  return (
    <>
      <div className="right-10 top-10 z-30 flex flex-col gap-3">
        <div
          id="recipe_select_list_holder"
          className="border border-black p-3 rounded-lg mb-5"
        >
          <span className="-mt-6 block bg-white px-2 border border-black ml-0 rounded-xl mb-2 w-36">
            Recipe Execution
          </span>
          {/*  <select onChange={handleSequence} className="recipe_select_list_holder w-48 mb-2 bg-gray-100 border-2 border-black font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white ">
            <option>Select Sequence</option> 
            {sequences && sequences.map((sequence, i) => <option key={sequence.id+i+"recipe-list"} value={sequence.id}>{sequence.sequenceName}</option>)}
  </select> */}
          <select
            onChange={handleRecipe}
            className="recipe_select_list_holder bg-gray-100 border-2 border-black font-bold text-sm px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white w-full"
          >
            <option>Select Recipe</option>
            {recipes && recipes.length>0 && 
            recipes.map((recipe, i) => 
            <option key={recipe.id+i+"recipe-list"} value={recipe.id}>{recipe.recipeName}</option>
            )}
          </select>
          {selectedRecipe &&
          selectedRecipe.id &&
          selectedSequence &&
          selectedSequence.id ? (
            // (!instanceId ? (
              (iecState?.find(o => o.name === "Sequence").currentValue!='RUNNING' ? (
              <>
            <button
              onClick={() => executeRecipe()}
              className=" bg-gray-100 border-2 border-black font-bold text-xl px-2 mt-1 w-full py-1 rounded-lg transition-all hover:bg-black hover:text-white "
            >
              Execute
            </button>
            
            <span>Completed recipe: {selectedRecipe.recipeName}</span></>
          ) : <>
            
            <button
              onClick={() => stopTestExec()}
              className="bg-gray-100 border-2 border-black font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white w-full"
            >
              Abort
            </button>
            <div className="text-xs">
              Step No : {iecState?.find((o) => o.name === "ISOEtchRecipeSequence_StepTime").currentValue}&nbsp;&nbsp;|&nbsp;
              Time : {iecState?.find((o) => o.name === "ISOEtchRecipeSequence_StepTime").currentValue} Sec
            </div>
          <span>Executing recipe: {selectedRecipe.recipeName}</span>
            </>
            )
          ) : (
            ""
          )}

          
        </div>
        <button
          onClick={() => handlepumpdown()}
          className="bg-btnSecondary shadow-shadow100 font-bold text-md px-2 py-1 rounded-lg transition-all hover:bg-white"
        >
          Pump Down
        </button>

        <button
          onClick={() => handVent()}
          className="bg-btnSecondary shadow-shadow100 font-bold text-md px-2 py-1 rounded-lg transition-all hover:bg-white"
        >
          Vent
        </button>
        <div
          id="test_exec_holder"
          className="border border-black p-3 rounded-lg mb-5 mt-5"
        >
          <span className="-mt-6 block bg-white px-2 border border-black ml-0 rounded-xl mb-2 w-36 leading-tight pb-1">
            Maintenance Execution
          </span>
          <select
            onChange={handleLoopTestSequence}
            className="recipe_select_list_holder w-full mb-2 bg-gray-100 border-2 border-black font-bold text-sm px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white pl-1"
          >
            <option>Select Sequence</option>
            {loopTestSequences &&
              loopTestSequences.map((sequence, i) => (
                <option
                  key={sequence.id + i + "recipe-list"}
                  value={sequence.id}
                >
                  {sequence.sequenceName}
                </option>
              ))}
          </select>
          <span>Loop Count</span>
          <input
            className="w-10 border border-black mr-2 ml-2 text-center"
            type="text"
            value={currLoop}
            onChange={(e) => setCurrLoop(() => e.target.value)}
          />
          <button
            className="bg-btnSecondary shadow-shadow100 font-bold text-md px-2 py-1 rounded-lg transition-all hover:bg-white mr-2"
            onClick={() => updateLoopCnt()}
          >
            Set
          </button>
          <span>Status:&nbsp;{runningStatus}</span>
          <br />
          <span>
            Remaining Loop:&nbsp;
            {
              iecState?.find((o) => o.name === "SequenceLoopRemaining")
                .currentValue
            }
          </span>
          {!instanceId ? (
            <button
              onClick={() => startTestExec()}
              className="bg-btnSecondary shadow-shadow100 mt-1 font-bold text-lg px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white w-full"
            >
              Start
            </button>
          ) : (
            ""
          )}

          {instanceId ? (
            <button
              onClick={() => stopTestExec()}
              className="bg-gray-100 border-2 border-black font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-black hover:text-white w-full"
            >
              Abort
            </button>
          ) : (
            ""
          )}
        </div>
        {/* <div
            className={` z-50 flex right-24 bg-gray-700  rounded-3xl top-4  ${
              isOn ? "flashcolor " : "opacity-10 h-12"
            } `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="#ffffff"
              class={`w-16 h-16  absolute -left-8 ${isOn ? " " : ""}`}
            >
              <path
                stroke-linecap="round"
                fill={alarmColor}
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
            <div className=" text-lg font-semibold text-white ">
              <div className="px-3 py-3 pl-8 pr-6">Alarm</div>
              {isOn && (
                <div className="Alarmbox text-lg font-thin p-4 bg-black rounded-b-3xl ">{alarmChannels}</div>
              )}
            </div>
            {isOn && (
              <button onClick={()=>handleAlarm()} className="absolute right-0 -bottom-5 bg-green-900 hover:bg-green-700 text-white border border-gray-400 px-2 py-0.5 rounded-full shadow-xl text-base">
                Clear
              </button>
            )}
          </div> */}
      </div>
    </>
  );
}
