import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const defaultGasBoxConfiguration = [
    {
      object: "MFC",
      name: "MFC7",
      readChannelName: "MAIN.MFC7.In.Flow_Reading",
      writeChannelName: "MAIN.MFC7.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC7.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC7out",
      feedBackChannel: "MAIN.MFC7.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC8",
      readChannelName: "MAIN.MFC8.In.Flow_Reading",
      writeChannelName: "MAIN.MFC8.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC8.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC8out",
      feedBackChannel: "MAIN.MFC8.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC9",
      readChannelName: "MAIN.MFC9.In.Flow_Reading",
      writeChannelName: "MAIN.MFC9.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC9.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC9out",
      feedBackChannel: "MAIN.MFC9.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC10",
      readChannelName: "MAIN.MFC10.In.Flow_Reading",
      writeChannelName: "MAIN.MFC10.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC10.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC10out",
      feedBackChannel: "MAIN.MFC10.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC11",
      readChannelName: "MAIN.MFC11.In.Flow_Reading",
      writeChannelName: "MAIN.MFC11.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC11.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC11out",
      feedBackChannel: "MAIN.MFC11.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "MFC",
      name: "MFC12",
      readChannelName: "MAIN.MFC12.In.Flow_Reading",
      writeChannelName: "MAIN.MFC12.Out.Flow_SP",
      feedBackChannel: "MAIN.MFC12.In.Flow_Reading",
      currentValue: 0,
      setValue:0,
    },
    {
      object: "MFC",
      name: "MFC12out",
      feedBackChannel: "MAIN.MFC12.Out.Flow_SP",
      currentValue: 0,
    },
    {
      object: "DownValve",
      name: "DV7",
      readChannelName: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV8",
      readChannelName: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV9",
      readChannelName: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV10",
      readChannelName: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV11",
      readChannelName: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "DownValve",
      name: "DV12",
      readChannelName: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV7",
      readChannelName: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV8",
      readChannelName: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV9",
      readChannelName: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV10",
      readChannelName: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV11",
      readChannelName: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
    {
      object: "UpValve",
      name: "UV12",
      readChannelName: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
      writeChannelName: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
      feedBackChannel: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
      currentValue: false,
    },
  ];
  const channelService = new ChannelService(viaConfig.channelServiceOptions);
  const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);

  
export default function IoEditor() {
    const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
    const [config,setConfig] = useState()
    const channels = useRef();
    const subscriptionRef = useRef();
    const [gasBoxState, setGasBoxState] = useState();
    const [allchannels, setallchannels] = useState();


    const fetchChannelsStates = async () => {
        let a=[];
        const response = await channelService.ChannelStates();
        console.log("ChannelStates: ", { response });
        if (response) {
            channels.current = response;
           
            setallchannels( channels.current)
            console.log(' channels.current', channels.current)
            channels.current.map((i,j)=>(
                j==0?
                a = [{
                    object: i.ioObject,
                    name: i.channelName,
                    readChannelName: i.channelName,
                    writeChannelName: i.channelName,
                    feedBackChannel:  i.channelName,
                    currentValue:  i.data.value,
                }]
                :
                a = [...a,{
                    object: i.ioObject,
                    name: i.channelName,
                    readChannelName: i.channelName,
                    writeChannelName: i.channelName,
                    feedBackChannel:  i.channelName,
                    currentValue:  i.data.value,
                }]
            ))
            setConfig(a)
            updateGasBoxState(channels.current);
        }
       
        };
    const updateGasBoxState = (updatedChannels) => {
        let index = -1
        let gasBoxConfigurationState = config;
        updatedChannels.forEach((item) => {
            const config = gasBoxConfigurationState?.find((obj, objIndex) => {
            index = objIndex;
            return obj.feedBackChannel === item.channelName
            })
            if (config) {
            config.currentValue = item.data.value;
            gasBoxConfigurationState[index] = config;
            }
        })
        setGasBoxState(() => gasBoxConfigurationState);
        
    }
    const subscribeToNotification = () => {

        const onNewMessage = (message) => {
            const channel = JSON.parse(message);
            updateGasBoxState([channel])
        };

        let channelSubscriptions = [];

        allchannels.forEach(config => {
            channelSubscriptions.push(config.channelName);
        })
        const subscription = {
            channels: channelSubscriptions,
            onNewMessage: onNewMessage,
        };

        subscriptionRef.current = subscription;
        channelSubscriptionService.subscribe(subscription);
    };
    const unSubscribe = () => {
        channelSubscriptionService.unSubscribe(subscriptionRef.current);
    };
    useEffect(() => {
        const fetChannelData = async () => {
            await fetchChannelsStates();
            subscribeToNotification();
        };
        fetChannelData();
        return () => {
            unSubscribe();
        };
    }, []);







  return (<>
            <div>
              {allchannels?.map((i,j)=>(<>
                <div className="grid grid-cols-6">
                    <div className="col-span-1">{i.ioObject}</div>  
                    <div className="col-span-2">{i.channelName}</div> 
                    <div className="col-span-1">{i.type}</div> 
                    <div className="col-span-1">{i.data.value}</div> 
                    <div className="col-span-1">{i.direction}</div>  
                </div>
              </> ))}
            </div>
            
  </>);
}