import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
import Alarm from '../alarm/Alarm';
import Pumpdown from "./dicing/pumpdown";
import Motor from "./Motor2";
import MotorControls from "./MotorControls";
import EmbossedBtn from "./EmbossedBtn";
import DicingGasbox from "./dicing/dicingGasbox";
import { getAllChambers, getAllRecipesByChamber, getAllSequences } from "../../api";
import DicingMoterModel from "./dicing/dicingMoterModel";
import DicingChamber from "./dicing/dicingChamber";
import DicingPressureMode from "./dicing/dicingPressureMode";
import DicingSourcePoverModel from "./dicing/dicingSourcePoverModel";
import DicingBiasPoverModel from "./dicing/dicingBiasPoverModel";

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const defaultGasBoxConfiguration = [
  {
    object: "MFC",
    name: "MFC7",
    readChannelName: "MAIN.MFC7.In.Flow_Reading",
    writeChannelName: "MAIN.MFC7.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC7.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC7out",
    feedBackChannel: "MAIN.MFC7.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC8",
    readChannelName: "MAIN.MFC8.In.Flow_Reading",
    writeChannelName: "MAIN.MFC8.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC8.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC8out",
    feedBackChannel: "MAIN.MFC8.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC9",
    readChannelName: "MAIN.MFC9.In.Flow_Reading",
    writeChannelName: "MAIN.MFC9.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC9.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC9out",
    feedBackChannel: "MAIN.MFC9.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC10",
    readChannelName: "MAIN.MFC10.In.Flow_Reading",
    writeChannelName: "MAIN.MFC10.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC10.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC10out",
    feedBackChannel: "MAIN.MFC10.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC11",
    readChannelName: "MAIN.MFC11.In.Flow_Reading",
    writeChannelName: "MAIN.MFC11.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC11.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC11out",
    feedBackChannel: "MAIN.MFC11.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "MFC",
    name: "MFC12",
    readChannelName: "MAIN.MFC12.In.Flow_Reading",
    writeChannelName: "MAIN.MFC12.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC12.In.Flow_Reading",
    currentValue: 0,
    setValue:0,
  },
  {
    object: "MFC",
    name: "MFC12out",
    feedBackChannel: "MAIN.MFC12.Out.Flow_SP",
    currentValue: 0,
  },
  {
    object: "DownValve",
    name: "DV7",
    readChannelName: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC7_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DV8",
    readChannelName: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC8_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DV9",
    readChannelName: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC9_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DV10",
    readChannelName: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC10_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DV11",
    readChannelName: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC11_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "DownValve",
    name: "DV12",
    readChannelName: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC12_Downstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UV7",
    readChannelName: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC7_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UV8",
    readChannelName: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC8_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UV9",
    readChannelName: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC9_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UV10",
    readChannelName: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC10_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UV11",
    readChannelName: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC11_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "UpValve",
    name: "UV12",
    readChannelName: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
    writeChannelName: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
    feedBackChannel: "MAIN.MFC12_Upstream.Out.On_Off_cmd",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm1",
    feedBackChannel: "MAIN.Foreline_Pmp.In.Alarm_fbk",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm2",
    feedBackChannel: "MFC1_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm3",
    feedBackChannel: "MFC2_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm4",
    feedBackChannel: "MFC3_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm5",
    feedBackChannel: "MFC4_SP_Greaterthan_FullScale",
    currentValue: false,
  },
  {
    object: "Alarm",
    name: "Alarm",
    feedBackChannel: "Global",
    currentValue: false,
  },
  {
    object: "MFC_SEQ_Cmd",
    name: "MFC_SEQ_Cmd",
    feedBackChannel: "MFC_SEQ_Cmd",
    currentValue: null,
  },
  {
    object: "MFC_SEQ_Name",
    name: "MFC_SEQ_Name",
    feedBackChannel: "MFC_SEQ_Name",
    currentValue: null,
  },
  {
    object: "PM1",
    name: "PM1.WaferID",
    feedBackChannel: "PM1.WaferID",
    currentValue: "",
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepNum",
    feedBackChannel: "DicingRecipeSequence_StepNum",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepLoopCounter",
    feedBackChannel: "DicingRecipeSequence_StepLoopCounter",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_SeqElapsedTime",
    feedBackChannel: "DicingRecipeSequence_SeqElapsedTime",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_SeqTotalTime",
    feedBackChannel: "DicingRecipeSequence_SeqTotalTime",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepLoopTotal",
    feedBackChannel: "DicingRecipeSequence_StepLoopTotal",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "SequenceRemainingCount",
    feedBackChannel: "RemainingCount", //for remaining 
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepTime",
    feedBackChannel: "DicingRecipeSequence_StepTime",
    currentValue: null,
  },
  {
    object: "DicingChamberSource_Power",
    name: "Source_Power.In.Actual_Forward_Power",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.Actual_Forward_Power",
    currentValue: null,
  },
  {
    object: "DicingChamberSource_Power",
    name: "Source_Power.In.Actual_Reflected_Power",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.Actual_Reflected_Power",
    currentValue: null,
  },
  {
    object: "DicingChamberBias_Power",
    name: "Bias_Power.In.Actual_Forward_Power",
    feedBackChannel: "MAIN.DicingChamberBias_Power.In.Actual_Forward_Power",
    currentValue: null,
  },
  {
    object: "DicingChamberBias_Power",
    name: "Bias_Power.In.Actual_Reflected_Power",
    feedBackChannel: "MAIN.DicingChamberBias_Power.In.Actual_Reflected_Power",
    currentValue: null,
  },
  // {
  //   object: "RPSPowerSupply",
  //   name: "MAIN.RPSPowerSupply.In.Actual_Voltage",
  //   feedBackChannel: "MAIN.RPSPowerSupply.In.Actual_Voltage",
  //   currentValue: null,
  // },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChuck_Motor_Config_Process_Position',
    readChannelName: 'DicingChuck_Motor_Config_Process_Position',
    writeChannelName: 'DicingChuck_Motor_Config_Process_Position',
    feedBackChannel: 'DicingChuck_Motor_Config_Process_Position',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChuck_Motor_Config_Transfer_Position',
    readChannelName: 'DicingChuck_Motor_Config_Transfer_Position',
    writeChannelName: 'DicingChuck_Motor_Config_Transfer_Position',
    feedBackChannel: 'DicingChuck_Motor_Config_Transfer_Position',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Actual_motor_position',
    readChannelName: 'MAIN.DicingChamberServoMotor.In.Actual_motor_position',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.In.Actual_motor_position',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Actual_motor_velocity',
    readChannelName: 'MAIN.DicingChamberServoMotor.In.Actual_motor_velocity',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.In.Actual_motor_velocity',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChamberServoMotor',
    readChannelName: 'MAIN.DicingChamberServoMotor.Out.Control_word',
    writeChannelName: 'MAIN.DicingChamberServoMotor.Out.Control_word',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.Out.Control_word',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorServoOnSeq',
    readChannelName: 'Status_DicingChuckMotorServoOnSeq',
    writeChannelName: 'Status_DicingChuckMotorServoOnSeq',
    feedBackChannel: 'Status_DicingChuckMotorServoOnSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorHomeSeq',
    readChannelName: 'Status_DicingChuckMotorHomeSeq',
    feedBackChannel: 'Status_DicingChuckMotorHomeSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorProcessPosSeq',
    readChannelName: 'Status_DicingChuckMotorProcessPosSeq',
    feedBackChannel: 'Status_DicingChuckMotorProcessPosSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'Status_DicingChuckMotorTransferPosSeq',
    readChannelName: 'Status_DicingChuckMotorTransferPosSeq',
    feedBackChannel: 'Status_DicingChuckMotorTransferPosSeq',
    currentValue: null
  },
  {
    object: 'DicingChamberServoMotor',
    name: 'DicingChamberServoMotorTarget_velocity',
    readChannelName: 'MAIN.DicingChamberServoMotor.Out.Target_velocity',
    writeChannelName: 'MAIN.DicingChamberServoMotor.Out.Target_velocity',
    feedBackChannel: 'MAIN.DicingChamberServoMotor.Out.Target_velocity',
    currentValue: null
  },
  {
    object: 'DicingChamberManometer',
    name: 'DicingChamberManometer1',
    readChannelName: 'MAIN.DicingChamberManometer.In.Sensor_Value',
    writeChannelName: 'MAIN.DicingChamberManometer.In.Sensor_Value',
    feedBackChannel: 'MAIN.DicingChamberManometer.In.Sensor_Value',
    currentValue: null
  },
  {
    object: 'DicingChamberPiraniGuage',
    name: 'DicingChamberPiraniGuage1',
    readChannelName: 'MAIN.DicingChamberPiraniGuage.In.Sensor_Value',
    writeChannelName: 'MAIN.DicingChamberPiraniGuage.In.Sensor_Value',
    feedBackChannel: 'MAIN.DicingChamberPiraniGuage.In.Sensor_Value',
    currentValue: null
  },
  {
    object: 'DicingChamberPumpPiraniGuage',
    name: 'DicingChamberPumpPiraniGuage',
    readChannelName: 'MAIN.DicingChamberPumpPiraniGuage.In.Sensor_Value',
    writeChannelName: 'MAIN.DicingChamberPumpPiraniGuage.In.Sensor_Value',
    feedBackChannel: 'MAIN.DicingChamberPumpPiraniGuage.In.Sensor_Value',
    currentValue: null
  },
  {
    object: 'DicingChamberThrottleValve',
    name: 'DicingChamberThrottleValveControlMode',
    readChannelName: 'MAIN.DicingChamberThrottleValve.In.Control_Mode', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberThrottleValve.Out.Control_Mode',
    feedBackChannel: 'MAIN.DicingChamberThrottleValve.In.Control_Mode', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberThrottleValve',
    name: 'DicingChamberThrottleValve',
    readChannelName: 'MAIN.DicingChamberThrottleValve.In.Actual_Pressure', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberThrottleValve.Out.Target_Pressure',
    feedBackChannel: 'MAIN.DicingChamberThrottleValve.In.Actual_Pressure', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberThrottleValve',
    name: 'DicingChamberThrottleValveActual_Position',
    readChannelName: 'MAIN.DicingChamberThrottleValve.In.Actual_Position', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberThrottleValve.Out.Target_Position',
    feedBackChannel: 'MAIN.DicingChamberThrottleValve.In.Actual_Position', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberTurbo_Pump',
    name: 'DicingChamberTurbo_PumpSpeed',
    readChannelName: 'MAIN.DicingChamberTurbo_Pump.In.Turbo_Speed', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberTurbo_Pump.In.Turbo_Speed',
    feedBackChannel: 'MAIN.DicingChamberTurbo_Pump.In.Turbo_Speed', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberTurbo_Pump',
    name: 'DicingChamberTurbo_PumpStatus',
    readChannelName: 'MAIN.DicingChamberTurbo_Pump.In.Rotation', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberTurbo_Pump.Out.Turbo_Start_Stop',
    feedBackChannel: 'MAIN.DicingChamberTurbo_Pump.In.Rotation', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberSource_Power',
    name: 'DicingChamberSource_Power',
    readChannelName: 'MAIN.DicingChamberSource_Power.In.RF_ON_Indication', 
    writeChannelName: 'MAIN.DicingChamberSource_Power.Out.RF_ON_Request',
    feedBackChannel: 'MAIN.DicingChamberSource_Power.In.RF_ON_Indication',
    currentValue: null
  },
  {
    object: 'DicingChamberSource_Power',
    name: 'DicingChamberSource_PowerSetpoint',
    readChannelName: 'MAIN.DicingChamberSource_Power.Out.Setpoint', 
    // readChannelName: 'MAIN.DicingChamberSource_Power.In.Actual_Setpoint', 
    writeChannelName: 'MAIN.DicingChamberSource_Power.Out.Setpoint',
    feedBackChannel: 'MAIN.DicingChamberSource_Power.Out.Setpoint', 
    // feedBackChannel: 'MAIN.DicingChamberSource_Power.In.Actual_Setpoint', 
    currentValue: null
  },
  {
    object: 'DicingChamberBias_Power',
    name: 'DicingChamberBias_Power',
    readChannelName: 'MAIN.DicingChamberBias_Power.In.RF_ON_Indication',
    writeChannelName: 'MAIN.DicingChamberBias_Power.Out.RF_ON_Request',
    feedBackChannel: 'MAIN.DicingChamberBias_Power.In.RF_ON_Indication', 
    currentValue: null
  },
  {
    object: 'DicingChamberBias_Power',
    name: 'DicingChamberBias_PowerSetpoint',
    readChannelName: 'MAIN.DicingChamberBias_Power.Out.Setpoint', 
    // readChannelName: 'MAIN.DicingChamberBias_Power.In.Actual_Setpoint', 
    writeChannelName: 'MAIN.DicingChamberBias_Power.Out.Setpoint',
    // feedBackChannel: 'MAIN.DicingChamberBias_Power.In.Actual_Setpoint', 
    feedBackChannel: 'MAIN.DicingChamberBias_Power.Out.Setpoint', 
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Sequence',
    readChannelName: 'Status_DicingRecipeSequence', //to be changed to in channel
    writeChannelName: 'Status_DicingRecipeSequence',
    feedBackChannel: 'Status_DicingRecipeSequence', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingChamberOnlineSeq',
    readChannelName: 'Status_DicingChamberOnlineSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingChamberOnlineSeq',
    feedBackChannel: 'Status_DicingChamberOnlineSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberCtrl',
    name: 'DicingChamberCtrl',
    readChannelName: 'DicingChamberCtrl_In_Status', //to be changed to in channel
    writeChannelName: 'DicingChamberCtrl_In_Status',
    feedBackChannel: 'DicingChamberCtrl_In_Status', //to be changed to in channel
    currentValue: false
  },
  {
    object: 'Sequence',
    name: 'Status_DicingVentSeq',
    readChannelName: 'Status_DicingVentSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingVentSeq',
    feedBackChannel: 'Status_DicingVentSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingPumpdownSeq',
    readChannelName: 'Status_DicingPumpdownSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingPumpdownSeq',
    feedBackChannel: 'Status_DicingPumpdownSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingCyclePurge',
    readChannelName: 'Status_DicingCyclePurge', //to be changed to in channel
    writeChannelName: 'Status_DicingCyclePurge',
    feedBackChannel: 'Status_DicingCyclePurge', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingLeakUpRateSeq',
    readChannelName: 'Status_DicingLeakUpRateSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingLeakUpRateSeq',
    feedBackChannel: 'Status_DicingLeakUpRateSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberSlit_Valve',
    name: 'DicingChamberSlit_ValveClose',
    // readChannelName: 'MAIN.DicingChamberSlit_Valve.Out.Slit_Close', 
    writeChannelName: 'MAIN.DicingChamberSlit_Valve.Out.Slit_Close',
    feedBackChannel: 'MAIN.DicingChamberSlit_Valve.Out.Slit_Close', 
    currentValue: null
  },
  {
    object: 'DDI_M7StatusChannels',
    name: 'DDI_M7StatusChannelsClose',
    feedBackChannel: 'MAIN.DDI_M7StatusChannels.In.DicingSlit_Valve_CloseStatus', 
    currentValue: false
  },
  {
    object: 'DicingChamberSlit_Valve',
    name: 'DicingChamberSlit_ValveOpen',
    // readChannelName: 'MAIN.DicingChamberSlit_Valve.Out.Slit_Open', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberSlit_Valve.Out.Slit_Open',
    feedBackChannel: 'MAIN.DicingChamberSlit_Valve.Out.Slit_Open', //to be changed to in channel
    currentValue: null
  }, 
  {
    object: 'DDI_M7StatusChannels',
    name: 'DDI_M7StatusChannelsOpen',
    feedBackChannel: 'MAIN.DDI_M7StatusChannels.In.DicingSlit_Valve_OpenStatus', 
    currentValue: false
  },
  {
    object: 'DicingChamberManoIsolation_Valve',
    name: 'DicingChamberManoIsolation_Valve',
    readChannelName: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd',
    feedBackChannel: 'MAIN.DicingChamberManoIsolation_Valve.Out.Open_cmd', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberVent_Valve',
    name: 'DicingChamberVent_Valve',
    readChannelName: 'MAIN.DicingChamberVent_Valve.Out.Open_cmd', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberVent_Valve.Out.Open_cmd',
    feedBackChannel: 'MAIN.DicingChamberVent_Valve.Out.Open_cmd', //to be changed to in channel
    currentValue: null
  },
  //Newly added - TurboPurge
  {
    object: 'DicingChamberTurboPurge_Valve', // this is for TurboPurge
    name: 'DicingChamberTurboPurge_Valve',
    readChannelName: 'MAIN.DicingChamberTurboPurge_Valve.Out.Open_cmd', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberTurboPurge_Valve.Out.Open_cmd',
    feedBackChannel: 'MAIN.DicingChamberTurboPurge_Valve.Out.Open_cmd', //to be changed to in channel
    currentValue: null
  },
  //turbobacking
  {
    object: 'DDI_M10CommandChannels',
    name: 'DDI_M10CommandChannelsRough_Pump',
    readChannelName: 'MAIN.DDI_M10CommandChannels.Out.Backing_Pump_StopCmd', //to be changed to in channel
    writeChannelName: 'MAIN.DDI_M10CommandChannels.Out.Backing_Pump_StopCmd',
    feedBackChannel: 'MAIN.DDI_M10CommandChannels.Out.Backing_Pump_StopCmd', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DDI_M9CommandChannels',
    name: 'DDI_M9CommandChannelsDryPump',
    readChannelName: 'MAIN.DDI_M9CommandChannels.Out.Dry_Pump_On_Command', //to be changed to in channel
    writeChannelName: 'MAIN.DDI_M9CommandChannels.Out.Dry_Pump_On_Command',
    feedBackChannel: 'MAIN.DDI_M9CommandChannels.Out.Dry_Pump_On_Command', //to be changed to in channel
    currentValue: null
  },
  //RoughLine
  {
    object: 'DicingChamberRoughPump_Valve',
    name: 'DicingChamberRoughPump_Valve',
    readChannelName: 'MAIN.DicingChamberRoughPump_Valve.Out.Open_cmd', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberRoughPump_Valve.Out.Open_cmd',
    feedBackChannel: 'MAIN.DicingChamberRoughPump_Valve.Out.Open_cmd', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'DicingChamberTurboIsolation_Valve',
    name: 'DicingChamberTurboIsolation_Valve',
    readChannelName: 'MAIN.DicingChamberTurboIsolation_Valve.Out.Open_cmd', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberTurboIsolation_Valve.Out.Open_cmd',
    feedBackChannel: 'MAIN.DicingChamberTurboIsolation_Valve.Out.Open_cmd', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Interlocks',
    name: 'DicingChamberInterlockStatus',
    readChannelName: 'DicingChamberInterlockStatus', //to be changed to in channel
    writeChannelName: 'DicingChamberInterlockStatus',
    feedBackChannel: 'DicingChamberInterlockStatus', //to be changed to in channel
    currentValue: false
  },

];
const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);


export default function DicingRIEChamber() {
  const gasBoxConfiguration = useRef(defaultGasBoxConfiguration);
  const channels = useRef();
  const subscriptionRef = useRef();
  const [gasBoxState, setGasBoxState] = useState();

 const [motorscreen,setMotorscreen] = useState(false)
 const [modescreen,setModescreen] = useState(false)
 const [sourcescreen,setSourcescreen] = useState(false)
 const [biasscreen,setBiasscreen] = useState(false)
 const [onlineindicator,setOnlineindicator] = useState(false)

  const [time, settime] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [sequences, setSequences] = useState([]);
  const [loopTestSequences, setLoopTestSequences] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [instanceId, setInstanceId] = useState("");
  const [runningStatus, setRunningStatus] = useState("");
  const [currLoop, setCurrLoop] = useState(0);

  
  const [forwardModel, setForwardModel] = useState(null);
  const [forwardvalue, setForwardvalue] = useState(null);

  let execIntervalId = 0;
  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    console.log("ChannelStates: ", { response });
    if (response) {
        channels.current = response;
        updateGasBoxState(channels.current);
    }
    };
const updateGasBoxState = (updatedChannels) => {
    let index = -1
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    updatedChannels.forEach((item) => {
        const config = gasBoxConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
        })
        if (config) {
        config.currentValue = item.data.value;
        gasBoxConfigurationState[index] = config;
        }
    })
    // setAlarm();
    setGasBoxState(() => gasBoxConfigurationState);
}
const subscribeToNotification = () => {

    const onNewMessage = (message) => {
        const channel = JSON.parse(message);
        updateGasBoxState([channel])
    };

    let channelSubscriptions = [];

    gasBoxConfiguration.current.forEach(config => {
        channelSubscriptions.push(config.feedBackChannel);
    })
    const subscription = {
        channels: channelSubscriptions,
        onNewMessage: onNewMessage,
    };

    subscriptionRef.current = subscription;
    channelSubscriptionService.subscribe(subscription);
};
const unSubscribe = () => {
    channelSubscriptionService.unSubscribe(subscriptionRef.current);
};
const IOWrite = (channelName, value) => {
  console.log("Channels: ", { channels });
  console.log("IOWrite: ", { channelName, value });
  if (channelName) {
    const channel = channels.current?.find(obj => {
      return obj.channelName === channelName
    })
    if (channel) {
      channel.data.value = value;
      channelService.Write(channel).then(() => {
      });
    }
  }
}
// const getRunningStatusTestExec = async (currInstanceId) => {
//   await fetch(
//     process.env.REACT_APP_VIA_RULE_ORCHES_API +
//       "/api/Sequence/" +
//       currInstanceId,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         // Add any other headers as needed
//       },
//     }
//   )
//     .then((response) => {
//       // Check if the request was successful
//       if (!response.ok) {
//         throw new Error("Could not get execution status.");
//       }
//       // Parse the response as JSON
//       return response.json();
//     })
//     .then((data) => {
//       // Handle the JSON data
//       let runStatus = data.runtimeStatus;

//       setRunningStatus(() => runStatus);
//       if (
//         runStatus == "TERMINATED" ||
//         runStatus == "COMPLETED" ||
//         runStatus == "FAILED"
//       ) {
//         clearInterval(execIntervalId);
//         execIntervalId = 0;
//         setInstanceId(()=>'');
//         localStorage.removeItem("dicingChbrLoopInstId")
//       }
//     })
//     .catch((error) => {
//       // Handle any errors that occurred during the fetch
//       console.error("Fetch error get running status:", error);
//     });
// };


useEffect(() => {
    const fetChannelData = async () => {
        await fetchChannelsStates();
        subscribeToNotification();
    };
    fetChannelData();
    return () => {
        unSubscribe();
    };
}, []);


  useEffect(() => {
    const interval = setInterval(() => {
      settime(new Date())
    }, 1000);
  
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const getRecipes = async () => {
    const chambers = await getAllChambers()
    if(chambers){
      const chamber = chambers?.filter((x)=> x.chamberName == 'Dicing RIE Chamber')[0]
      const recipes = await getAllRecipesByChamber(chamber.id);
      if (recipes) {
        setRecipes(recipes.DATA);
      }
    }
  };
  const getSequencelist = async () => {
    const sequences = await getAllSequences();
    console.log('dicing sequence',sequences);
    if(sequences && sequences.length>0){
      const filteredByRecipe = sequences.filter(sequence => sequence.sequenceType === "RecipeSequence"); 
      setSequences(filteredByRecipe)
      const selected = sequences.filter((sequence) => sequence.sequenceName === "DicingRecipeSequence");
        setSelectedSequence(selected[0]);
      const filteredByLoopTest = sequences.filter(sequence => sequence.sequenceType === "LoopSequence"); 
      setLoopTestSequences(filteredByLoopTest)
    }
  }
  const fetchRunningStatus = ()=>{
    const currInstanceId = localStorage.getItem('dicingChbrLoopInstId');
    const selStoredDicingRecipe = JSON.parse(localStorage.getItem('selStoredDicingRecipe'));
    if(selStoredDicingRecipe && selStoredDicingRecipe.id)
      setSelectedRecipe(()=> selStoredDicingRecipe)
    if(currInstanceId && currInstanceId!=""){
      setInstanceId(()=> currInstanceId)      
        // execIntervalId = setInterval(()=>{
        //   getRunningStatusTestExec(currInstanceId);
        // },1000);
      }
  }
  const executeRecipe = async () => {debugger
    if(!selectedRecipe || !selectedRecipe.id){
      return;
    }
    var formdata = {
      token: "",
      ruleType: "RecipeSequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: selectedSequence?.sequenceName,
      inputData: {
        RecipeId: selectedRecipe.id,
      },
    };
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(formdata),
      }
    )
      .then((response) => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Could not start execution.");
        }
        // Parse the response as JSON
        return response.json();
      })
      .then((data) => {
        // Handle the JSON data
        let currInstanceId = data[0].resultValue;
        setInstanceId(() => currInstanceId);
        localStorage.setItem('isoEtchChbrLoopInstId',currInstanceId);
        localStorage.setItem('selStoredRecipe',JSON.stringify(selectedRecipe));
        // execIntervalId = setInterval(() => {
        //   getRunningStatusTestExec(currInstanceId);
        // }, 1000);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error start execution:", error);
      });
  };
  const stopTestExec = async (e) => {
    setRunningStatus(() => 'ABORTING');
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API +
        "/api/Sequence/abort/DicingRecipeSequence?execType=RECIPE",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
      }
  ).then(response => {
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Could not stop execution.');
    }
    // Parse the response as JSON
    return response.json();
  })
  .then(data => {
    // Handle the JSON data
    if(data.statusCode==200){
      // closingRecipe();
      setTimeout(()=>{IOWrite('Status_DicingRecipeSequence', 'TERMINATED');setRunningStatus(() => 'TERMINATED');},5000)
      localStorage.removeItem('isoEtchChbrLoopInstId')
      clearInterval(execIntervalId)
    } else{
    }
    
  })
  .catch(error => {
    // Handle any errors that occurred during the fetch
    console.error('Fetch error in stop execution:', error);
  });
    };

  const terminateSequence = async () => {
    let e = localStorage.getItem('currentdicingSequence');
    
    if(e) {
      await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API +
          "/api/Sequence/terminate?instanceId="+e ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          }
        }
      ).then(response => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error('Could not stop execution.');
        }
      })
      .then(data => {
        if(data.statusCode==200){
          localStorage.removeItem('currentdicingSequence')
        } else{
        }
        
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('Fetch error in stop execution:', error);
      });
    }

    };
    const executesequence = async (e) => {
      localStorage.setItem('currentdicingSequence',e);
      var formdata = {
        token: "",
        ruleType: "Sequence",
        ruleGroups: [],
        ruleSubGroups: [],
        rule: "",
        sequence: e,
        inputData: {},
      };
      try {
        fetch(
          process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formdata),
          }
        )
          .then(async (response) => {
            console.log('Response status:', response.status);
            console.log('Response text:', await response.text()); // Log the raw response body to inspect it
    
            // Ensure the response is OK before trying to parse JSON
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Server error or invalid JSON');
            }
          })
          .then((data) => {
            if (data && Array.isArray(data)) {
              const resultValue = data[0]?.resultValue;
              if (resultValue != null) {
                console.log("resultValue print", resultValue);
              }
            }
          })
          .catch((error) => {
            console.error("Parsing error:", error.message);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const CheckDicingInterlock = async () => {
      setOnlineindicator(false)
      try {
        let url ="/api/Interlock/CheckDicingInterlock"
        
       const response = await fetch(process.env.REACT_APP_VIA_RULE_ORCHES_API+url);
        // Check if the request was successful
        if (response.ok) {
          const result = await response.json();
          // Assuming the API returns a boolean, set the state accordingly
          console.log(' fetch data ok')
          if(result.status==true) {
              executesequence("DicingChamberOnlineSeq")
            console.log(' fetch data success')
          } else {
            setOnlineindicator(true)
          }
        } else {
          console.log('Failed to fetch data')          
        }
      } catch (err) {
        console.log(err)
      }
    };


    const handleRecipe = (e) => {
      const val = e.target.value;debugger
      if (val) {
        const selected = recipes.filter((recipe) => recipe.id == e.target.value);
        if(selected[0] && selected[0].id){
          setSelectedRecipe(selected[0]);
          localStorage.setItem('selStoredDicingRecipe',JSON.stringify(selected[0]));
        }else{
          setSelectedRecipe({});
        }
      } else {
        setSelectedRecipe({});
      }
    };

const handleforward = () => {
  if(forwardModel=='source') {
    IOWrite('MAIN.DicingChamberSource_Power.Out.Setpoint', forwardvalue);    
  } else if(forwardModel=='bias') {
    IOWrite('MAIN.DicingChamberBias_Power.Out.Setpoint', forwardvalue);   
  }
  setForwardModel(null)
}
const SourcemodeFunction = async (e) => {
  let val = !e;
  IOWrite('MAIN.DicingChamberSource_Power.Out.RF_ON_Request', val);
  // setMode(val)
  console.log(val)
 }
const BiasmodeFunction = async (e) => {
  let val = !e;
  IOWrite('MAIN.DicingChamberBias_Power.Out.RF_ON_Request', val);
  // setMode(val)
  console.log(val)
 }
  useEffect(() => {
    getSequencelist();
    getRecipes();
    // fetchRunningStatus();
  }, []);
  return (<>
    <div className="flex gap-2 w-full h-full p-1 dysingcontainer-bg">
      <div className="flex flex-1 bg-blue-100 p-3 rounded-md">{time?.toString}
        
        <div className="w-80">
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-4">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Process Monitor</div>
            <div className="relative w-full h-6 -mt-4">
              {gasBoxState?.find(o => o.name === "DicingChamberCtrl").currentValue==true?
              <div className="float-right w-10 bg-green-500 h-3 absolute right-1 rounded-full"></div>
                :  
                <div className="float-right w-10 bg-gray-600 h-3 absolute right-1 rounded-full"></div>
                }
            </div>
            <div className="grid grid-cols-6 gap-2 text-xs  font-semibold">
            <div class="bg-blue-200 px-2 py-1 flex-1 rounded- dysing-input col-span-2">{gasBoxState?.find(o => o.name === "PM1.WaferID").currentValue }</div>
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input  col-span-2">Step : {gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepNum").currentValue }</div>
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input  col-span-2">{gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepTime").currentValue } Sec</div>             
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input col-span-3">Loop : {gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepLoopCounter").currentValue } / {gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepLoopTotal").currentValue }</div>          
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input col-span-3">Time : {gasBoxState?.find(o => o.name === "DicingRecipeSequence_SeqElapsedTime").currentValue } / {gasBoxState?.find(o => o.name === "DicingRecipeSequence_SeqTotalTime").currentValue }</div>
            </div>
            <div className="mb-4">
              <div class=" flex gap-2 mt-4 text-sm">Process Step</div>
              <div className="grid grid-cols-4 gap-2 mt-2">
                <select className="text-sm  bg-gray-200 px-2 py-1 col-span-3 dysing-bg-gray rounded font-semibold text-gray-600"
                 onChange={handleRecipe} >
                  <option>Select Recipe</option>
                    {recipes && recipes.length>0 && 
                      recipes.map((recipe, i) => 
                      <option key={recipe.id+i+"recipe-list"} value={recipe.id}>{recipe.recipeName}</option>
                    )}
                </select>
                {/* {!instanceId ? (   */}
                  
                {gasBoxState?.find(o => o.name === "Sequence").currentValue!='RUNNING' ? (  
                  <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none"  
                    onClick={() => executeRecipe()}>
                    RUN
                  </button>
                ) : (gasBoxState?.find(o => o.name === "Sequence").currentValue=='ABORTING' ? (  
                  <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none"  
                    disabled >
                    Aborting
                  </button>
                ):
                <button
                  onClick={() => stopTestExec()}
                  className="dysing-btn-bg py-1 shadow-md rounded hover:shadow-none"
                >
                  Abort
                </button>
                
                )}
              </div>
              <div className="mt-3">
              {/* {!instanceId ? */}
               {gasBoxState?.find(o => o.name === "Sequence").currentValue!='RUNNING' ? 
               (gasBoxState?.find(o => o.name === "Sequence").currentValue=='TERMINATED' 
               || gasBoxState?.find(o => o.name === "Sequence").currentValue=='ABORTING' ?
               <span>Aborted recipe: {selectedRecipe.recipeName}</span>: 
                <span>Completed recipe: {selectedRecipe.recipeName}</span>) 
              :
                <span>Executing recipe: {selectedRecipe.recipeName}</span>
              }
              </div>
            </div>

          </div>
          
          {/* <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none w-20 mt-3" onClick={() => setMotorscreen(!motorscreen)}>Motor</button> */}
        </div>
        <div className="flex-1 text-center px-1">
          
          <DicingChamber gasBoxState={gasBoxState} IOWrite={IOWrite} setMotorscreen={setMotorscreen} setModescreen={setModescreen}/>
        </div>
        <div className="">
          <DicingGasbox />

          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1" 
            onClick={() => setSourcescreen(!sourcescreen)}
            >Source Power</div>
            
            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
              onClick={()=>SourcemodeFunction(gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberSource_Power.Out.RF_ON_Request").currentValue)}>  
                <div className="px-2 text-sm font-semibold">{gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberSource_Power.Out.RF_ON_Request").currentValue==true ? "ON" : "OFF"}</div>
                <div className={`h-5 w-5 rounded-full ${gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberSource_Power.Out.RF_ON_Request").currentValue==true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
             </div>
            <div className="grid grid-cols-2 mb-3 gap-2  font-bold text-gray-600 relative">
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1  w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow">
                  <div class="relative z-20 cursor-pointer" onClick={()=>{setForwardModel('source');setForwardvalue(gasBoxState?.find(o => o.name === "DicingChamberSource_PowerSetpoint").currentValue )}}>	
                    {gasBoxState?.find(o => o.name === "DicingChamberSource_PowerSetpoint").currentValue.toFixed(3) }
                  </div>
                </div>
              </div>
              
              <div className="flex">                
                <div class="gap-2 mt-1 w-16">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">{gasBoxState?.find(o => o.name === "Source_Power.In.Actual_Reflected_Power").currentValue}</div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-16">C1 \ C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">0</div>
                </div>
              </div>

              {forwardModel == 'source' &&
                  <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                    <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                      <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' 
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                     <div className="flex mt-2 rounded-xl overflow-hidden">
                        <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)} 
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                        <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' 
                        onClick={() => handleforward()}>Update</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1"  
            // onClick={() => setBiasscreen(!biasscreen)}
            >Bias Power</div>
            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
              onClick={()=>BiasmodeFunction(gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue)}>  
                <div className="px-2 text-sm font-semibold">{gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue==true ? "ON" : "OFF"}</div>
                <div className={`h-5 w-5 rounded-full ${gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue==true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
             </div>
            <div className="grid grid-cols-2 mb-3 gap-2 font-bold text-gray-600 relative">
              <div className="flex col-span-2">                
                <div class="gap-2 mt-1 w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow ">
                  <div class="relative z-20"  onClick={()=>{setForwardModel('bias');setForwardvalue(gasBoxState?.find(o => o.name === "DicingChamberBias_PowerSetpoint").currentValue )}}>
                    {gasBoxState?.find(o => o.name === "DicingChamberBias_PowerSetpoint").currentValue }</div>
                </div>
              </div>
              
              <div className="flex">                
                <div class="gap-2 mt-1 w-16">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">{gasBoxState?.find(o => o.name === "Bias_Power.In.Actual_Reflected_Power").currentValue}</div>
                </div>
              </div>
              <div className="flex">                
                <div class="gap-2 mt-1 w-16">C1 \ C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              {forwardModel == 'bias' &&
                  <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                    <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                      <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' 
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                     <div className="flex mt-2 rounded-xl overflow-hidden">
                        <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)} 
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                        <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' 
                        onClick={() => handleforward()}>Update</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="dysing-bg pt-3 px-2 rounded-md w-28"> 
        {/* <button className="border-4 border-gray-100 dysing-input-green px-2 py-6 text-xs font-bold w-full rounded-lg">ONLINE</button>
        <div className="mt-6 text-xs font-bold text-center">Commands</div> */}
        <div className=" ">
          <button className={`  diceembossed-btn  py-3  !w-full text-xs font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue==='RUNNING'&& '!bg-blue-300 !text-slate-600 ' }
         ${onlineindicator==true&&  'blink-bg' }
          `}
         
             onClick={()=>CheckDicingInterlock()} 
             disabled={
              gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue==='RUNNING'? true :
              gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue==='RUNNING'? true :
              gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue==='RUNNING'?true :
              gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
              gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
              false}
              
             >
              ONLINE 
              {gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue==='RUNNING'&& <><br /><strong className="text-xs font-bold text-gray-800">Running</strong></>}
          </button>
          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs  font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue==='RUNNING'&& '!bg-blue-300 !text-slate-600' }`}  
                     disabled={
                      gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue==='RUNNING'?true:
                      gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue==='RUNNING'?true:
                      gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue==='RUNNING'?true:
                      gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
                      gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
                      false}
          >
            PURGE 
            {gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue==='RUNNING'&& <><br /><strong className="text-xs font-bold text-gray-800">Running</strong></>}
            </button>
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-sm font-bold leading-8 rounded-lg !text-gray-600 hover:bg-slate-200">WATER</button>
          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs  font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue==='RUNNING'&& '!bg-blue-300 !text-slate-600' }`}  
                     disabled={
                      gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue==='RUNNING'?true:
                      gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue==='RUNNING'?true:
                      gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue==='RUNNING'?true:
                      gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
                      gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
                      false}>
            LEAK RATE
            {gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue==='RUNNING'&& <><br /><strong className="text-xs font-bold text-gray-800">Running</strong></>}
          </button>
          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs  font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue==='RUNNING'&& '!bg-blue-300 !text-slate-600' }`}  
           disabled={
            gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue==='RUNNING'?true:
            gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue==='RUNNING'?true:
            gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue==='RUNNING'?true:
            gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
            gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
            false}
          onClick={()=>executesequence('DicingPumpdownSeq')}
          >PUMPDOWN {gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue==='RUNNING'&& <><br /><strong className="text-xs font-bold text-gray-800">Running</strong></>}
          </button>
          <button className="diceembossed-btn  py-3 px-2 !w-full !h-16 !pt-3 mt-3 text-sm font-bold leading-5 rounded-lg !text-gray-600 hover:bg-slate-200">CYCLE PURGE</button>

          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue==='RUNNING'&& '!bg-blue-300 !text-slate-600' }`}  
            disabled={
              gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue==='RUNNING'?true:
              gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue==='RUNNING'?true:
              gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue==='RUNNING'?true:
              gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
              gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
              false}
          onClick={()=>executesequence('DicingVentSeq')}>
            VENT 
            {gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue==='RUNNING'&& <><br />
            <strong className="text-xs font-bold text-gray-800">Running</strong></>}
          </button>
          
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400" 
          onClick={()=>terminateSequence()}
          disabled={
            gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue=='RUNNING'?false:
            gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue=='RUNNING'?false:
            gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue=='RUNNING'?false:
            gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
            gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
            true}
          >ABORT</button>             
        </div>

      </div>
    </div>
   {motorscreen&& 
    <div className="bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden z-30" >
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setMotorscreen(!motorscreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-4 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Motor</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setMotorscreen(!motorscreen)}>✖</button>
          </div>
        </div>     
        <DicingMoterModel gasBoxState={gasBoxState} IOWrite={IOWrite}/>        
      </div>
    </div>}
    {modescreen &&
    <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setModescreen(!modescreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-20 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Mode</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setModescreen(!modescreen)}>✖</button>
          </div>
        </div>     
        <DicingPressureMode gasBoxState={gasBoxState} IOWrite={IOWrite} setModescreen={setModescreen} modescreen={modescreen}/>        
      </div>
    </div>
    }
    
    {/* {sourcescreen &&
    <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setSourcescreen(!sourcescreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Source Power</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setSourcescreen(!sourcescreen)}>✖</button>
          </div>
        </div>     
        <DicingSourcePoverModel gasBoxState={gasBoxState} IOWrite={IOWrite} setSourcescreen={setSourcescreen} sourcescreen={sourcescreen}/>        
      </div>
    </div>
    }*/}
    {biasscreen &&
    <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
      <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setBiasscreen(!biasscreen)}></div>
      <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
        <div className="-mt-7 mb-3 flex">
          <div className="flex-1">
            <div className="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Bias Power</div>
          </div>
          <div>
            <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setBiasscreen(!biasscreen)}>✖</button>
          </div>
        </div>     
        <DicingBiasPoverModel gasBoxState={gasBoxState} IOWrite={IOWrite} setBiasscreen={setBiasscreen} biasscreen={biasscreen}/>        
      </div>
    </div>
    } 

</>  );
}